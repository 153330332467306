import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BodyTitleWithSub } from '../../../component/UI/BodyTitleSection';
import YoutubeList from '../../../component/UI/YoutubeList';
import { url } from '../../../component/commonVariable';

export default function VideoLectureParts() {
  const navigate = useNavigate();
  const [youtubeList, setYoutubeList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/academy/main/videoLecture/getList`).then(result => {
      // console.log(result.data);
      setYoutubeList(result.data);
    });
  }, []);

  const goToAll = () => {
    navigate('/Academy/Video');
  };

  return (
    <Box sx={{ mb: '80px' }}>
      <BodyTitleWithSub mainTitle="동영상 강의" subTitle="전체보기" onClickSub={goToAll} />
      <Box sx={{ height: '1px', backgroundColor: '#DADADA', mb: '20px', mt: '12px' }} />
      <YoutubeList youtubeList={youtubeList} />
    </Box>
  );
}
