import React, { useMemo, useRef } from 'react';
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';
import '../css/Quill.css';
import imageCompression from 'browser-image-compression';
import PropTypes from 'prop-types';
import { url } from '../../commonVariable';

Quill.register('modules/ImageResize', ImageResize);

const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  // ["blockquote"],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
];

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'background',
  'color',
  'link',
  'image',
  'video',
  'width',
];

const options = {
  maxSizeMB: 5,
};

export default function QuillEditor({ editQuillContents, setEditQuillContents, directory }) {
  const quillRef = useRef();

  // 에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement('input');
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener('change', async () => {
      // console.log('온체인지');
      const originalFile = input.files[0];

      const file = await compressImage(originalFile);
      // console.log(file, '압축 파일');
      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();

      formData.append('fileName', input.files[0].name);
      formData.append('directory', directory);
      formData.append('file', file); // formData는 키-밸류 구조
      // 백엔드 multer라우터에 이미지를 보낸다.
      try {
        const result = await axios.post(`${url}/common/addImage`, formData);
        // console.log('성공 시, 백엔드가 보내주는 데이터', result.data);
        const IMG_URL = result.data;
        // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
        // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
        // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

        // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
        const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
        // 1. 에디터 root의 innerHTML을 수정해주기
        // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
        // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
        // editor.root.innerHTML =
        //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

        // 2. 현재 에디터 커서 위치값을 가져온다
        const range = editor.getSelection();
        // 가져온 위치에 이미지를 삽입한다
        editor.insertEmbed(range.index, 'image', IMG_URL);
      } catch (error) {
        console.log('실패했어요ㅠ');
      }
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          // 이미지 처리는 직접 만든 imageHandler라는 함수로 처리하는
          image: imageHandler,
        },
      },
      ImageResize: {
        modules: ['Resize', 'DisplaySize'],
      },
    };
  }, []);

  // 이미지 압축
  const compressImage = async file => {
    let newFile;

    if (file.type === 'image/gif') {
      newFile = file;
    } else {
      try {
        // console.log('왔어요')
        const compressedFile = await imageCompression(file, options);
        newFile = compressedFile;
      } catch (error) {
        console.log(error);
      }
    }

    return newFile;
  };

  const onChangeContents = e => {
    // console.log(e);
    setEditQuillContents(e);
  };

  return (
    <div>
      <ReactQuill
        placeholder="텍스트를 입력해주세요"
        theme="snow"
        value={editQuillContents}
        onChange={onChangeContents}
        ref={quillRef}
        modules={modules}
        formats={formats}
        preserveWhitespace
      />
    </div>
  );
}

QuillEditor.defaultProps = {
  editQuillContents: '',
  setEditQuillContents: () => {},
  directory: '',
};

QuillEditor.propTypes = {
  editQuillContents: PropTypes.string,
  setEditQuillContents: PropTypes.func,
  directory: PropTypes.string,
};
