import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardMedia, Grid, Stack, Box, Button } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from '../../../component/UI/TextStyle';
import { changeDateDash, changeDateKor } from '../../../component/commonFunction';
import { LectureBadge } from '../../../component/UI/Badge';
import EditLectureCategoryModal from '../../../component/UI/Admin/EditLectureCategoryModal';

/** 강의 리스트 UI */
export default function AdminLectureList({
  lectureList,
  category,
  refreshSwitch,
  setRefreshSwitch,
}) {
  const navigate = useNavigate();

  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editData, setEditData] = useState({});

  const goToDetail = id => {
    window.open(`/Academy/Lecture/${category}/${id}`);
  };

  const goToEdit = id => {
    navigate(`/Admin/Academy/EditLecture/${category}/${id}`);
  };

  const openEditModal = (id, categ) => {
    setEditData({ lectureId: id, category: categ });
    setEditCategoryModal(true);
  };

  return (
    <Grid container spacing={{ lg: '40px' }}>
      {lectureList.map(function (each) {
        return (
          <Grid item xs={6} lg={3} key={each.lectureId}>
            <CardMedia
              component="img"
              alt={`lecture Image-${each.title}`}
              image={each.img || ''}
              onClick={() => goToDetail(each.lectureId)}
              sx={{
                width: { xs: '150px', lg: '240px' },
                height: { xs: '150px', lg: '240px' },
                cursor: 'pointer',
              }}
            />
            <Box
              onClick={() => goToDetail(each.lectureId)}
              sx={[
                each.isPublished === 0 && { backgroundColor: 'rgba(0,0,0, 0.4)' },
                { cursor: 'pointer' },
              ]}
            >
              <NormalContentsTitle sx={{ mt: '14px', mb: '3px' }}>{each.title}</NormalContentsTitle>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="5px">
                <NormalContentsSubTitle>{changeDateKor(each.lectureStart)}</NormalContentsSubTitle>
                {each.lectureStartHour && (
                  <NormalContentsSubTitle sx={{ letterSpacing: { xs: '-0.03em', lg: '0em' } }}>
                    {`${each.lectureStartHour} 개강 `}
                  </NormalContentsSubTitle>
                )}
                <NormalContentsSubTitle>{` / ${each.lectureNum}`}</NormalContentsSubTitle>
              </Stack>
            </Box>

            <LectureBadge endDate={each.enrollEnd} />

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing="3px"
              sx={{ my: '10px', fontSize: '14px' }}
            >
              <Box sx={{ fontWeight: 600 }}>공개 {each.isPublished === 1 ? 'O' : 'X'}</Box>
              <Box>/ 조회수 {each.viewCounting}</Box>
              <Box sx={{ mt: '10px' }}>{`/ 작성일 ${changeDateDash(each.createdAt)}`}</Box>
            </Stack>
            <Stack direction="row">
              <Button
                variant="outlined"
                onClick={() => goToEdit(each.lectureId)}
                sx={{ mr: '9px' }}
              >
                수정하기
              </Button>
              <Button variant="outlined" onClick={() => openEditModal(each.lectureId, category)}>
                강의 변경하기
              </Button>
            </Stack>
          </Grid>
        );
      })}

      {editCategoryModal && (
        <EditLectureCategoryModal
          editData={editData}
          setModalSwitch={setEditCategoryModal}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
        />
      )}
    </Grid>
  );
}

AdminLectureList.defaultProps = {
  lectureList: [],
  category: '',
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

AdminLectureList.propTypes = {
  // eslint-disable-next-line
  lectureList: PropTypes.array,
  category: PropTypes.string,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
