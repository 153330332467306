import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import YoutubeSection from '../../../component/UI/YoutubeSection';

export default function Video() {
  return (
    <div>
      <FirstSecionSimple
        titleText="더라인아카데미 동영상 강의"
        subNav
        backImg="Academy/video_background.png"
      />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="동영상강의" />
      <YoutubeSection company="academy" category="동영상 강의" />
    </div>
  );
}
