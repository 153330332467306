import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { TheLineBodyContainer } from '../ContainerStyle';
import { BodyTitle } from '../TextStyle';
import { youtubeItemNumber, url } from '../../commonVariable';
import Pagination from '../../Pagination';
import AddEditYoutubeModal from './AddEditYoutubeModal';
import AdminYoutubeList from './AdminYoutubeList';

export default function AdminYoutubeSection({ category }) {
  // books || acadmy
  const whichCompany = window.location.pathname.split('/')[2].toLowerCase();

  const [youtubeList, setYoutubeList] = useState([
    { youtubeId: 1, title: '로딩중입니다', link: '' },
  ]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/youtube/getList/${category}/${page}`).then(result => {
      // console.log(result.data);
      setYoutubeList(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [refreshSwitch, page]);

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/youtube/getTotalNum/${category}`).then(result => {
      // console.log(result.data);
      setTotalItem(result.data.totalNum);
    });
  }, [refreshSwitch]);

  const openAddModal = () => {
    setEditData({});
    setAddEditModalSwitch(true);
  };

  const openEditModal = data => {
    setEditData(data);
    setAddEditModalSwitch(true);
  };

  return (
    <TheLineBodyContainer>
      <Stack direction="row" spacing="30px" sx={{ mb: '15px' }}>
        <BodyTitle>{category}</BodyTitle>
        <Button onClick={openAddModal} sx={{ fontSize: '15px' }}>
          새 콘텐츠 등록
        </Button>
      </Stack>

      <AdminYoutubeList youtubeList={youtubeList} adminFunc={openEditModal} />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={youtubeItemNumber} />

      {addEditModalSwitch && (
        <AddEditYoutubeModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
          category={category}
          whichCompany={whichCompany}
          editData={editData}
        />
      )}
    </TheLineBodyContainer>
  );
}

AdminYoutubeSection.defaultProps = {
  category: '',
};

AdminYoutubeSection.propTypes = {
  // eslint-disable-next-line
  category: PropTypes.string,
};
