import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Grid } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from './TextStyle';

/** 유튜브 리스트 UI */
export default function YoutubeList({ youtubeList }) {
  const openYoutube = youtubeLink => {
    window.open(youtubeLink);
  };

  return (
    <Grid container spacing={{ xs: '33px', lg: '25px' }}>
      {youtubeList.map(function (each) {
        let img;
        if (each.link.includes('.be')) {
          img = `https://i.ytimg.com/vi/${each.link.split('/')[3]}/maxresdefault.jpg`;
        } else {
          img = `https://i.ytimg.com/vi/${each.link.split('=')[1]}/maxresdefault.jpg`;
        }

        return (
          <Grid item xs={12} md={4} key={each.youtubeId} sx={{ mb: { lg: '20px' } }}>
            <CardMedia
              component="img"
              alt={`youtube Image${each.title}`}
              image={img}
              onClick={() => openYoutube(each.link)}
              sx={{
                width: 1,
                borderRadius: '12px',
                aspectRatio: '480/270',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.85,
                },
              }}
            />
            <NormalContentsTitle sx={{ mt: { xs: '10px', lg: '14px' } }}>
              {each.title}
            </NormalContentsTitle>
            <NormalContentsSubTitle sx={{ mt: { xs: '2px', lg: '2px' } }}>
              {each.subTitle}
            </NormalContentsSubTitle>
          </Grid>
        );
      })}
    </Grid>
  );
}

YoutubeList.defaultProps = {
  youtubeList: [],
};

YoutubeList.propTypes = {
  // eslint-disable-next-line
  youtubeList: PropTypes.array,
};
