import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Tabs, Tab, Box } from '@mui/material';
import { url } from '../../../component/commonVariable';
import QuillEditorViewer from '../../../component/UI/QuillEditorViewer';

export default function BookDescription() {
  const { bookId } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const introSection = useRef();
  const indexSection = useRef();
  const authorSection = useRef();

  const [editQuillIntro, setEditQuillIntro] = useState('');
  const [editQuillIndex, setEditQuillIndex] = useState('');
  const [editQuillAuthor, setEditQuillAuthor] = useState('');

  const editorField = [
    { label: '책 소개', data: editQuillIntro, setData: setEditQuillIntro, ref: introSection },
    { label: '목차', data: editQuillIndex, setData: setEditQuillIndex, ref: indexSection },
    { label: '저자 소개', data: editQuillAuthor, setData: setEditQuillAuthor, ref: authorSection },
  ];

  useEffect(() => {
    axios.get(`${url}/books/book/getDetail/editor/${bookId}`).then(result => {
      // console.log(result.data);
      // 데이터 받아서 각 editor에 쏴주는
      editorField.forEach(function (each) {
        each.setData(result.data.filter(e => e.category === each.label)[0].contents);
      });
    });
  }, []);

  // 스크롤 이벤트 등록
  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    };
  }, []);

  const goToSection = ref => {
    const topOfElement = ref.current.offsetTop - 200;
    window.scroll({ top: topOfElement, behavior: 'smooth' });
  };

  const handleFollow = () => {
    // 스크롤 위치에 따라 탭 활성화
    if (window.scrollY > authorSection.current.offsetTop - 250) {
      setSelectedTab(2);
    } else if (window.scrollY > indexSection.current.offsetTop - 250) {
      setSelectedTab(1);
    } else if (window.scrollY > introSection.current.offsetTop - 250) {
      setSelectedTab(0);
    }
  };

  return (
    <div>
      <Tabs
        value={editorField[selectedTab].label}
        textColor="inherit"
        variant="fullWidth"
        sx={{
          mt: { xs: '44px', lg: '84px' },
          position: 'sticky',
          zIndex: 110,
          top: { xs: '54px', lg: '104px', xl: '120px' },
        }}
        TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
      >
        {editorField.map(function (each, index) {
          return (
            <Tab
              onClick={() => goToSection(each.ref)}
              key={each.label}
              // (MUI 땜시) 임의로 넣은 value
              value={each.label}
              label={each.label}
              sx={[
                selectedTab === index
                  ? { backgroundColor: '#FFF', border: '2px solid #F5F5F5', fontWeight: 700 }
                  : { backgroundColor: '#F5F5F5', color: '#999999', fontWeight: 500 },
                { fontSize: { xs: '16px', lg: '20px' }, zIndex: 100, opacity: 1 },
              ]}
            />
          );
        })}
      </Tabs>

      <Box sx={{ px: { lg: '30px' }, py: '40px' }}>
        {editorField.map(function (each, index) {
          return (
            <Box
              ref={each.ref}
              key={`${each.label} 소개내용`}
              sx={[
                index === 2 ? null : { borderBottom: '3px solid #F6F8FF' },
                {
                  mt: '18px',
                  mb: '60px',
                },
              ]}
            >
              <Box
                sx={{
                  width: '24px',
                  height: { xs: '6px', lg: '6px' },
                  backgroundColor: '#161F2C',
                  mb: '20px',
                  ml: '15px',
                }}
              />
              <QuillEditorViewer contents={each.data} />
            </Box>
          );
        })}
      </Box>
    </div>
  );
}
