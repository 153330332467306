import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BodyTitleWithSub } from './BodyTitleSection';
import { youtubeMainHook } from '../../module/useEffectHooks';
import { NormalContentsTitle } from './TextStyle';

export default function YoutubeCarouselSection({ title, company, category, allLink }) {
  const youtubeList = youtubeMainHook(company, category);

  const navigate = useNavigate();

  // 드래그 방지를 위한
  let dragging = false;
  const sliderSettings = {
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,

    // 반응형 개수
    // responsive: [
    //   {
    //     breakpoint: 3000,
    //     settings: {
    //       slidesToShow: 2.2,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 1920,
    //     settings: {
    //       slidesToShow: 2.2,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 1279,
    //     settings: {
    //       slidesToShow: 2.2,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 899,
    //     settings: {
    //       slidesToShow: 1.1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1.1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
    // eslint-disable-next-line
    beforeChange: () => dragging = true,
    // eslint-disable-next-line
    afterChange: () => dragging = false,
  };

  // 반응형 안먹는...
  const SliderSettingsMob = {
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    // eslint-disable-next-line
    beforeChange: () => dragging = true,
    // eslint-disable-next-line
    afterChange: () => dragging = false,
  };

  const goToAll = () => {
    navigate(allLink);
  };

  const goToLink = link => {
    if (!dragging) {
      window.open(link);
    }
  };

  return (
    <Box>
      <BodyTitleWithSub mainTitle={title} subTitle="전체보기" onClickSub={goToAll} />
      <Box sx={{ height: '1px', backgroundColor: '#DADADA', mb: '20px', mt: '12px' }} />
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Slider {...sliderSettings}>
          {youtubeList.map(function (each) {
            let img;
            if (each.link.includes('.be')) {
              img = `https://i.ytimg.com/vi/${each.link.split('/')[3]}/maxresdefault.jpg`;
            } else {
              img = `https://i.ytimg.com/vi/${each.link.split('=')[1]}/maxresdefault.jpg`;
            }

            return (
              <Box key={each.youtubeId}>
                <CardMedia
                  alt={`youtube_${each.title}`}
                  onClick={() => {
                    goToLink(each.link);
                  }}
                  image={img}
                  sx={{
                    width: 0.97,
                    aspectRatio: '480/270',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    cursor: 'pointer',
                    ':hover': {
                      opacity: 0.85,
                    },
                  }}
                />
                <NormalContentsTitle sx={{ mt: '13px' }}>{each.title}</NormalContentsTitle>
              </Box>
            );
          })}
        </Slider>
      </Box>

      <Box sx={{ display: { lg: 'none' } }}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Slider {...SliderSettingsMob}>
          {youtubeList.map(function (each) {
            let img;
            if (each.link.includes('.be')) {
              img = `https://i.ytimg.com/vi/${each.link.split('/')[3]}/maxresdefault.jpg`;
            } else {
              img = `https://i.ytimg.com/vi/${each.link.split('=')[1]}/maxresdefault.jpg`;
            }

            return (
              <Box key={each.youtubeId}>
                <CardMedia
                  alt={`youtube_${each.title}`}
                  onClick={() => {
                    goToLink(each.link);
                  }}
                  image={img}
                  sx={{
                    width: { xs: 0.97, lg: '480px' },
                    height: { lg: '270px' },
                    aspectRatio: '480/270',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    cursor: 'pointer',
                    ':hover': {
                      opacity: 0.85,
                    },
                  }}
                />
                <NormalContentsTitle sx={{ mt: '13px' }}>{each.title}</NormalContentsTitle>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
}

YoutubeCarouselSection.defaultProps = {
  title: '',
  company: '',
  category: '',
  allLink: '',
};

YoutubeCarouselSection.propTypes = {
  title: PropTypes.string,
  company: PropTypes.string,
  category: PropTypes.string,
  allLink: PropTypes.string,
};
