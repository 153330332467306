import React from 'react';
import ReactPagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import './Pagenation.css';

export default function Pagination({ page, totalItem, setPage, itemNum }) {
  return (
    <Grid container direction="row" justifyContent="center">
      <Box sx={{ mt: '50px', display: { xs: 'none', md: 'block' } }}>
        <ReactPagination
          activePage={page}
          itemsCountPerPage={itemNum}
          totalItemsCount={totalItem}
          pageRangeDisplayed={10}
          hideFirstLastPages
          prevPageText={<ArrowBackIosNewRoundedIcon sx={{ fontSize: '15px' }} />}
          nextPageText={<ArrowForwardIosRoundedIcon sx={{ fontSize: '15px' }} />}
          onChange={setPage}
        />
      </Box>

      <Box sx={{ mt: '35px', display: { md: 'none' }, justifyContent: 'center' }}>
        <ReactPagination
          activePage={page}
          itemsCountPerPage={itemNum}
          totalItemsCount={totalItem}
          pageRangeDisplayed={10}
          hideFirstLastPages
          prevPageText="‹"
          nextPageText="›"
          onChange={setPage}
        />
      </Box>
    </Grid>
  );
}

Pagination.defaultProps = {
  page: 1,
  totalItem: 100,
  itemNum: 10,
  setPage: '',
};

Pagination.propTypes = {
  page: PropTypes.number,
  totalItem: PropTypes.number,
  itemNum: PropTypes.number,
  setPage: PropTypes.func,
};
