/* eslint-disable */
import React from 'react';
import { Box, Button, Typography } from '@mui/material';

export default function OpenCare() {
  const goToCafe = () => {
    window.open('https://cafe.naver.com/thelineopencare');
  };

  return (
    <div>
      <Box sx={{ backgroundColor: '#161F2C', height: { xs: '0.5px', lg: '0.8px' } }} />
      <Box
        sx={{
          backgroundColor: '#FBFBFB',
          mt: '70px',
          px: { xs: '25px', lg: '89px' },
          py: { xs: '35px', lg: '62px' },
          mb: { lg: '-30px' },
        }}
      >
        <Typography align="center" fontSize={{ xs:24, lg: 26, xl: 28 }} fontWeight={700} sx={{ mb: '18px' }}>
          더라인 오픈케어 카페
        </Typography>
        <Typography align="center" fontSize={{ xs: 16, lg: 18, xl: 20 }} sx={{ display:{ xs: 'none', lg: 'block' } }}>
          더라인 오픈케어 카페에서 더 많은 수강 후기를 확인하실 수 있습니다.<br />
          영화(미드) 자막 없이 보기, 영화 번역 맛보기, 출판번역 맛보기, 번역 근육 키우기 등<br />
          다양한 온라인 스터디도 무료로 진행되고 있으니 자유롭게 신청해서 참여해 보세요.<br />
        </Typography>

        <Typography align="center" fontSize={{ xs: 16, lg: 18, xl: 20 }} sx={{ display:{ lg: 'none' } }}>
          더라인 오픈케어 카페에서 더 많은 수강 후기를 확인하실 수 있습니다.
          영화(미드) 자막 없이 보기, 영화 번역 맛보기, 출판번역 맛보기, 번역 근육 키우기 등
          다양한 온라인 스터디도 무료로 진행되고 있으니 자유롭게 신청해서 참여해 보세요.<br />
        </Typography>

        <Button
          onClick={goToCafe}
          sx={{
            mt: { xs: '24px', lg: '33px', xl: '37px' },
            borderRadius: { xs: '8px', lg: '12px', xl: '16px' },
            fontSize: { xs: '16px', lg: '18px', xl: '20px' },
            width: { xs: 1, lg: '370px', xl: '384px' },
            height: { xs: '44px', lg: '50px', xl: '60px' },
          }}
        >
          더라인 오픈케어 카페 바로가기
        </Button>
      </Box>
    </div>
  );
}
