import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import './css/Quill.css';

export default function QuillEditorViewer({ contents }) {
  return <ReactQuill theme="bubble" readOnly value={contents} />;
}

QuillEditorViewer.defaultProps = {
  contents: {},
};

QuillEditorViewer.propTypes = {
  contents: PropTypes.string,
};
