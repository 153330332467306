import React from 'react';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function ConfirmModal({ modalSwitch, setModalSwitch, title, contents, func }) {
  const modalClose = () => setModalSwitch(false);

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 320,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '40px 40px 40px 40px',
            outline: 'none',
          }}
        >
          <Typography variant="h4" color="#333333" align="center" fontWeight={600} fontSize="26px">
            <Box sx={{ mb: '20px' }}>{title}</Box>
          </Typography>

          <Typography
            variant="body"
            color="#555555"
            align="center"
            fontWeight={400}
            lineHeight={1.35}
            fontSize="16px"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            <Box sx={{ mb: '30px' }}>{contents}</Box>
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={func}
              sx={{ width: '100px', height: '34px', fontSize: '18px', mr: '10px' }}
            >
              확인
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={modalClose}
              sx={{ width: '100px', height: '34px', fontSize: '18px' }}
            >
              취소
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

ConfirmModal.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
  title: '',
  contents: '',
  func: () => {},
};

ConfirmModal.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  title: PropTypes.string,
  contents: PropTypes.string,
  func: PropTypes.func,
};
