import moment from 'moment';
import 'moment/locale/ko';

/** window 오픈 함수 (url 새 창 열기) */
export const openWindow = url => {
  window.open(url);
};

/** s3 url로 바꿔주는 함수 */
export const backgroundURL = (url, isMobile) => {
  let answer;
  if (isMobile) {
    const newUrl = `${url.split('.')[0]}_mob.${url.split('.')[1]}`;
    answer = `url(https://thelineimage.s3.ap-northeast-2.amazonaws.com/${newUrl})`;
  } else {
    answer = `url(https://thelineimage.s3.ap-northeast-2.amazonaws.com/${url})`;
  }
  return answer;
};

/** 날짜 형 변환 YYYY-MM-DD */
export const changeDateDash = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYY-MM-DD');
  }
  return publishDate;
};

/** 날짜 형 변환 YYYY.MM.DD */
export const changeDateDot = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYY.MM.DD');
  }
  return publishDate;
};

/** 날짜 형 변환 YYYYMMDD */
export const changeDateNoDot = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYYMMDD');
  }
  return publishDate;
};

/** 날짜 형 변환 M월 D일(요일) */
export const changeDateKor = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('MMMM Do(dd)');
  }
  return publishDate;
};

/** 세 자리 마다 콤마를 추가 */
export const addComma = input => {
  let newInput = input;

  if (input) {
    newInput = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return newInput;
};
