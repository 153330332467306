import { Container } from '@mui/material';
import { styled } from '@mui/system';

/** width, px를 포함한 Container  */
export const FixedContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '1100px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
}));

/** width, pt, pb를 포함한 Container  */
export const TheLineBodyContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingTop: '50px',
    paddingBottom: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '1100px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '70px',
    paddingBottom: '110px',
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: '80px',
    paddingBottom: '125px',
  },
}));

/** 북스 & 아카데미 컨테이너 - width, pt, pb를 포함한 Container  */
export const BodyContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingTop: '36px',
    paddingBottom: '72px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '1100px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '48px',
    paddingBottom: '160px',
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: '54px',
    paddingBottom: '170px',
  },
}));
