import React from 'react';
import { CardMedia, Stack, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { TheLineBodyContainer } from '../../../component/UI/ContainerStyle';
import { BodyTitle } from '../../../component/UI/TextStyle';
import { openWindow } from '../../../component/commonFunction';

export default function Location() {
  return (
    <TheLineBodyContainer>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="11px">
        <BodyTitle>찾아오는길</BodyTitle>
        <BodyTitle color="primary" sx={{ fontWeight: 500, ml: { lg: '2px' } }}>
          더라인북스 & 더라인아카데미
        </BodyTitle>
      </Stack>

      <CardMedia
        component="img"
        alt="contact map"
        src="/contact.png"
        onClick={() => openWindow('https://naver.me/5uxcZUbI')}
        sx={{
          mt: { xs: '18px' },
          mb: { xs: '12px' },
          width: { xs: 1 },
          display: { lg: 'none' },
          cursor: 'pointer',
        }}
      />

      <CardMedia
        component="img"
        alt="contact map"
        src="/contact.png"
        onClick={() => openWindow('https://naver.me/5uxcZUbI')}
        sx={{
          mt: { lg: '18px', xl: '24px' },
          mb: { lg: '24px', xl: '26px' },
          width: { lg: '1080px' },
          height: { lg: '650px' },
          display: { xs: 'none', lg: 'block' },
          cursor: 'pointer',
        }}
      />
      <Table>
        <TableBody>
          <TableRow sx={{ borderTop: { lg: '0.5px solid #222222', xl: '1px solid #222222' } }}>
            <TableCell
              align="center"
              sx={{
                backgroundColor: '#F7F7F7',
                fontSize: { xs: '13.5px', lg: '18px', xl: '20px' },
                fontWeight: 700,
                width: { xs: '38px', lg: '180px', xl: '200px' },
                height: { lg: '75px', xl: '90px' },
              }}
            >
              주소
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: { xs: '13.5px', lg: '18px', xl: '20px' },
                pl: { xs: '10px', lg: '32px', xl: '40px' },
              }}
            >
              서울 구로구 디지털로30길 31 코오롱디지털타워빌란트2 1206호
            </TableCell>
          </TableRow>

          {/* <TableRow sx={{ borderTop: { lg: '0.5px solid #222222', xl: '1px solid #222222' } }}>
            <TableCell
              align="center"
              sx={{
                backgroundColor: '#F7F7F7',
                fontSize: { xs: '13.5px', lg: '18px', xl: '20px' },
                fontWeight: 700,
                height: { lg: '60px', xl: '70px' },
              }}
            >
              전화
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: { xs: '13.5px', lg: '18px', xl: '20px' },
                pl: { xs: '10px', lg: '32px', xl: '40px' },
              }}
            >
              <strong>02-332-1671</strong>
            </TableCell>
          </TableRow> */}

          <TableRow sx={{ borderTop: { lg: '0.5px solid #222222', xl: '1px solid #222222' } }}>
            <TableCell
              align="center"
              sx={{
                backgroundColor: '#F7F7F7',
                fontSize: { xs: '13.5px', lg: '18px', xl: '20px' },
                fontWeight: 700,
                height: { lg: '70px', xl: '90px' },
              }}
            >
              이메일
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: { xs: '13.5px', lg: '16px', xl: '18px' },
                pl: { xs: '10px', lg: '32px', xl: '40px' },
                lineHeight: 1.6,
              }}
            >
              <strong>더라인북스</strong> : thelinebooks@naver.com
              <br />
              <strong>더라인아카데미</strong> : theline77@naver.com
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TheLineBodyContainer>
  );
}
