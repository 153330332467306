import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { TheLineBodyContainer } from '../../../component/UI/ContainerStyle';
import { BodyTitle } from '../../../component/UI/TextStyle';
import { noticeItemNumber, url } from '../../../component/commonVariable';
import Pagination from '../../../component/Pagination';
import AdminLectureList from './AdminLectureList';

export default function AdminLectureSection({ category }) {
  const navigate = useNavigate();

  const [lectureList, setLectureList] = useState([{ lectureId: 1, title: '', img: '' }]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  useEffect(() => {
    axios.get(`${url}/admin/academy/lecture/getList/${category}/${page}`).then(result => {
      // console.log(result.data);
      setLectureList(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [page, refreshSwitch]);

  useEffect(() => {
    axios.get(`${url}/admin/academy/lecture/getTotalNum/${category}`).then(result => {
      // console.log(result.data);
      setTotalItem(result.data.totalNum);
    });
  }, [refreshSwitch]);

  const goToWrite = () => {
    navigate(`/Admin/Academy/EditLecture/${category}/add`);
  };

  return (
    <TheLineBodyContainer>
      <Stack direction="row" spacing="30px" sx={{ mb: '35px' }}>
        <BodyTitle>{category} 관리</BodyTitle>
        <Button onClick={goToWrite} sx={{ fontSize: '15px' }}>
          새 강의 등록
        </Button>
      </Stack>

      <AdminLectureList
        lectureList={lectureList}
        category={category}
        refreshSwitch={refreshSwitch}
        setRefreshSwitch={setRefreshSwitch}
      />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={noticeItemNumber} />
    </TheLineBodyContainer>
  );
}

AdminLectureSection.defaultProps = {
  category: '',
};

AdminLectureSection.propTypes = {
  // eslint-disable-next-line
  category: PropTypes.string,
};
