import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { BannerTitle } from '../../../component/UI/TextStyle';
import { backgroundURL } from '../../../component/commonFunction';
import LoginCheck from '../../../module/LoginCheck';

export default function AdminGate() {
  LoginCheck();

  const navigate = useNavigate();

  const pageList = [
    { label: '더라인 통합 페이지', description: '공지사항', link: '/Admin/Theline' },
    { label: '더라인북스', description: '배너, 도서, 트레일러, 인터뷰 등', link: '/Admin/Books' },
    { label: '더라인아카데미', description: '배너, 강의, TLS 등', link: '/Admin/Academy' },
  ];

  const goToUrl = link => {
    navigate(link);
  };

  return (
    <div>
      <Grid sx={{ display: { lg: 'none' }, height: '350px', mt: '160px' }}>
        <Typography fontSize={24} fontWeight={600}>
          관리자 기능은
          <br />
          PC 화면에서 사용해주세요!
        </Typography>
        <Button onClick={() => goToUrl('/')} sx={{ mt: '10px' }}>
          메인 화면으로 돌아가기
        </Button>
      </Grid>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          backgroundImage: backgroundURL('integrated/gate_background.png'),
          backgroundSize: 'cover',
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          sx={{
            height: { xs: '140px', md: '220px', lg: '450px', xl: '400px' },
          }}
        >
          <BannerTitle color="primary" sx={{ mb: { lg: '35px', xl: '36px' } }}>
            더라인 관리자 페이지
          </BannerTitle>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing="25px">
            {pageList.map(function (each) {
              return (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  key={each.label}
                  sx={{
                    width: 300,
                    height: 180,
                    py: '25px',
                    backgroundColor: '#FFF',
                    borderRadius: '8px',
                  }}
                >
                  <Box>
                    <Typography fontSize={22} fontWeight={600}>
                      {each.label}
                    </Typography>
                    <Typography fontSize={16} sx={{ mt: '10px' }}>
                      {each.description}
                    </Typography>
                  </Box>
                  <Button onClick={() => goToUrl(each.link)} sx={{ width: 120 }}>
                    바로가기
                  </Button>
                </Grid>
              );
            })}
          </Stack>
        </Grid>
      </Container>
    </div>
  );
}
