import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Grid, Button } from '@mui/material';
import { NormalContentsTitle } from '../TextStyle';
import { changeDateDash } from '../../commonFunction';
import { bannerRatioPC, bannerRatioMob } from '../../commonVariable';

/** 유튜브 리스트 UI */
export default function AdminBannerList({ bannerList, adminFunc, isPC }) {
  const openWindow = link => {
    window.open(link);
  };
  return (
    <Grid container spacing={{ lg: '50px' }}>
      {bannerList.map(function (each) {
        return (
          <Grid item xs={isPC ? 6 : 4} key={each.bannerId}>
            <CardMedia
              component="img"
              alt={`banner Image${each.bannerId}`}
              image={each.img || ''}
              onClick={() => openWindow(each.link)}
              sx={[
                isPC
                  ? { width: '530px', aspectRatio: bannerRatioPC }
                  : { width: '330px', aspectRatio: bannerRatioMob },
                {
                  cursor: 'pointer',
                  ':hover': {
                    opacity: 0.85,
                  },
                },
              ]}
            />
            <NormalContentsTitle sx={{ mt: '10px' }}>{each.title}</NormalContentsTitle>
            <Grid container justifyContent="space-between" sx={{ mt: '10px' }}>
              <Button variant="outlined" onClick={() => adminFunc(each)}>
                수정하기
              </Button>
              {`작성일 : ${changeDateDash(each.createdAt)} / `}
              {`클릭수 : ${each.clickCounting} / `}
              {`순서 : ${each.bannerOrder}`}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

AdminBannerList.defaultProps = {
  bannerList: [],
  adminFunc: () => {},
  isPC: true,
};

AdminBannerList.propTypes = {
  // eslint-disable-next-line
  bannerList: PropTypes.array,
  adminFunc: PropTypes.func,
  isPC: PropTypes.bool,
};
