import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CardMedia, Box, Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { url } from '../../../component/commonVariable';
import { addComma, changeDateDot } from '../../../component/commonFunction';
import MustRead from './MustRead';

export default function LectureIntroMob() {
  const { lectureId } = useParams();
  const [lectureData, setLectureData] = useState({});
  const [mustReadData, setMustReadData] = useState('');

  const dataList = [
    { label: '강사', keyName: 'teacher' },
    { label: '일정', keyName: 'lectureDate' },
    { label: '시간', keyName: 'lectureTime' },
    { label: '정원', keyName: 'maxPeople' },
    { label: '수강료', keyName: 'price' },
    { label: '참고', keyName: 'noti' },
  ];

  useEffect(() => {
    axios.get(`${url}/academy/lecture/getDetail/text/${lectureId}`).then(result => {
      // console.log(result.data);
      setLectureData(result.data[0]);
    });
  }, []);

  useEffect(() => {
    axios.get(`${url}/academy/lecture/getDetail/mustRead/${lectureId}`).then(result => {
      setMustReadData(result.data[0].contents);
    });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          border: '0.5px solid #DADADA',
          mt: '-15px',
          px: '20px',
          py: '20px',
        }}
      >
        <CardMedia
          component="img"
          alt={`lecture Image${lectureData.title}`}
          image={lectureData.img || '/logo192.png'}
          onClick={() => {}}
          sx={{
            width: 1,
            aspectRatio: '1',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        />
      </Box>

      <Box sx={{ px: '20px', mt: '20px' }}>
        <Typography align="left" fontSize={{ xs: 16 }} fontWeight={700}>
          {lectureData.title}
        </Typography>

        <Table sx={{ mt: '12px' }}>
          <TableBody>
            {dataList.map(function (each, index) {
              return (
                <TableRow key={each.label} sx={{ borderTop: '0.5px solid #C9C9C9' }}>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: '#F8F9FE',
                      fontSize: '13px',
                      fontWeight: 600,
                      width: '40px',
                      py: '7px',
                    }}
                  >
                    {each.label}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: '13px',
                      pl: '15px',
                      py: '7px',
                    }}
                  >
                    {index !== 1 && each.label !== '수강료' && lectureData[each.keyName]}
                    {index === 1 &&
                      `${changeDateDot(lectureData.lectureStart)} ~ ${changeDateDot(
                        lectureData.lectureEnd,
                      )} (${lectureData.lectureNum})`}
                    {each.label === '수강료' && `${addComma(lectureData[each.keyName])}원`}
                    {index === 1 && (
                      <Typography fontSize={10} fontWeight={500} color="#EA187D">
                        {lectureData.lectureDateNoti}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      {lectureData.mustRead === 1 && <MustRead mustReadData={mustReadData} />}
    </Box>
  );
}
