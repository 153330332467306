import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { LoginProvider } from './module/ContextAPI';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './module/ScrollToTop';

// MUI 전체 테마
const theme = createTheme({
  palette: {
    primary: {
      main: '#4891FF',
    },
    secondary: {
      main: '#10263B',
    },
    grey: {
      main: '#C9C9C9',
    },
    white: {
      main: '#FFFFFF',
    },
    green: {
      main: '#5AC351',
    },
  },
  typography: {
    body1: {
      color: '#333333',
    },
    body2: {
      color: '#333333',
    },
    h5: {
      color: '#333333',
    },
    h4: {
      color: '#333333',
    },
    h3: {
      color: '#333333',
    },
    fontFamily: [
      'Pretendard',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'system-ui',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        style: { fontWeight: 600 },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: { padding: 10, backgroundColor: '#FFFFFF' },
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        align: 'center',
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LoginProvider>
          <HelmetProvider>
            <ScrollToTop />
            <App />
          </HelmetProvider>
        </LoginProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
