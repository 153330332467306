import React from 'react';
import { FirstSecionAddSubTitle } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import LectureSection from '../../../component/UI/LectureSection';

export default function Offline() {
  return (
    <div>
      <FirstSecionAddSubTitle
        titleText="오프라인 강의"
        subText={`경력이 탄탄한 전문가들의 이야기를 현장에서 생생하게 들어 보세요.
          번역과 글쓰기, 출판 등 각 분야 전문가와 함께 업계 실무를 배우고
          다양한 과제를 수행하며 실력을 쌓을 수 있습니다.`}
        subNav
        backImg="Academy/offline_background.png"
      />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="오프라인 강의" />
      <LectureSection category="오프라인 강의" />
    </div>
  );
}
