import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  CardMedia,
  Stack,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { url } from '../../../component/commonVariable';
import { addComma, changeDateDot } from '../../../component/commonFunction';
import MustRead from './MustRead';

export default function LectureIntroPC() {
  const { lectureId } = useParams();
  const [lectureData, setLectureData] = useState({});
  const [mustReadData, setMustReadData] = useState('');

  const dataList = [
    { label: '강사', keyName: 'teacher' },
    { label: '일정', keyName: 'lectureDate' },
    { label: '시간', keyName: 'lectureTime' },
    { label: '정원', keyName: 'maxPeople' },
    { label: '수강료', keyName: 'price' },
    { label: '참고', keyName: 'noti' },
  ];

  useEffect(() => {
    axios.get(`${url}/academy/lecture/getDetail/text/${lectureId}`).then(result => {
      // console.log(result.data);
      setLectureData(result.data[0]);
    });
  }, []);

  useEffect(() => {
    axios.get(`${url}/academy/lecture/getDetail/mustRead/${lectureId}`).then(result => {
      setMustReadData(result.data[0].contents);
    });
  }, []);

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-start" spacing={{ lg: '45px', xl: '48px' }}>
        <CardMedia
          component="img"
          alt={`lecture Image${lectureData.title}`}
          image={lectureData.img || '/logo192.png'}
          onClick={() => {}}
          sx={{
            width: { xs: '265px', lg: '388px' },
            height: { xs: '265px', lg: '388px' },
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        />
        <Box sx={{ width: 1, pt: { lg: '10px' } }}>
          <Typography align="left" fontSize={{ lg: 32 }} fontWeight={700}>
            {lectureData.title}
          </Typography>

          <Table sx={{ mt: '14px' }}>
            <TableBody>
              {dataList.map(function (each, index) {
                return (
                  <TableRow
                    key={each.label}
                    sx={{ borderTop: { lg: '0.5px solid #C9C9C9', xl: '1px solid #C9C9C9' } }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: '#F8F9FE',
                        fontSize: { lg: '16px', xl: '18px' },
                        fontWeight: { lg: 600, xl: 700 },
                        width: '70px',
                        py: '8px',
                      }}
                    >
                      {each.label}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: { lg: '16px', xl: '18px' },
                        pl: '20px',
                        py: '10px',
                      }}
                    >
                      <Stack
                        key={each.label}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={{ lg: '12px' }}
                      >
                        <Box>
                          {index !== 1 && each.label !== '수강료' && lectureData[each.keyName]}
                          {index === 1 &&
                            `${changeDateDot(lectureData.lectureStart)} ~ ${changeDateDot(
                              lectureData.lectureEnd,
                            )} (${lectureData.lectureNum})`}
                          {each.label === '수강료' && `${addComma(lectureData[each.keyName])}원`}
                        </Box>
                        {index === 1 && (
                          <Typography fontSize={{ lg: 16 }} fontWeight={500} color="#EA187D">
                            {lectureData.lectureDateNoti}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Stack>

      {lectureData.mustRead === 1 && <MustRead mustReadData={mustReadData} />}
    </Box>
  );
}
