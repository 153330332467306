import React from 'react';
import LectureSection from '../../../component/UI/LectureSection';
import { FirstSecionAddSubTitle } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';

export default function SpecialLecture() {
  return (
    <div>
      <FirstSecionAddSubTitle
        titleText="특강"
        subText={`번역, 글쓰기, 출판 등 다양한 분야의 전문가를 초빙해 직접 이야기를 듣습니다.
          번역가로서 탄탄한 번역 실력을 쌓는 방법을 공유하는 한편,
          책쓰기나 출판 등 다른 분야로 영역을 확장할 수 있게 방향을 제시해 줍니다.`}
        subNav
        backImg="Academy/specialLecture_background.png"
      />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="특강" />
      <LectureSection category="특강" />
    </div>
  );
}
