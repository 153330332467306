import React from 'react';
import NoticeSection from '../../../component/UI/NoticeSection';
import MainGate from './MainGate';

export default function TheLineMain() {
  return (
    <div>
      <MainGate />
      <NoticeSection isMain />
    </div>
  );
}
