import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, Button } from '@mui/material';
import { BannerTitle } from './TextStyle';
import SubNavBar from '../NavBar/SubNavBar';
import { backgroundURL } from '../commonFunction';

/** 타이틀 제목만 있는 섹션 */
export function FirstSecionSimple({ backImg, titleText, titleColor, subNav }) {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundImage: { xs: backgroundURL(backImg, true), lg: backgroundURL(backImg, false) },
        backgroundSize: 'cover',
        height: { xs: '135px', md: '220px', lg: '250px', xl: '275px' },
      }}
    >
      {/* 반투명 subNav 필요한 섹션 */}
      {subNav && <SubNavBar translucent />}
      <Grid
        container
        direction="column"
        justifyContent="center"
        // 중앙 정렬을 위한 조정
        // subNavBar 높이 { xs: '30px', md: '40px', lg: '44px', xl: '48px' }
        sx={[
          subNav
            ? { height: { xs: '135px', md: '180px', lg: '206px', xl: '227px' } }
            : { height: { xs: '135px', md: '220px', lg: '250px', xl: '275px' } },
        ]}
      >
        <BannerTitle color={titleColor}>{titleText}</BannerTitle>
      </Grid>
    </Container>
  );
}

FirstSecionSimple.defaultProps = {
  backImg: '',
  subNav: false,
  titleText: '타이틀 텍스트를 입력해주세요',
  titleColor: '#FFF',
};

FirstSecionSimple.propTypes = {
  backImg: PropTypes.string,
  subNav: PropTypes.bool,
  titleText: PropTypes.string,
  titleColor: PropTypes.string,
};

/** 서브 타이틀도 포함된 섹션 */
export function FirstSecionAddSubTitle({ backImg, titleText, titleColor, subText, subNav }) {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundImage: { xs: backgroundURL(backImg, true), lg: backgroundURL(backImg, false) },
        backgroundSize: 'cover',
        height: { xs: '135px', md: '220px', lg: '250px', xl: '275px' },
      }}
    >
      {/* 반투명 subNav 필요한 섹션 */}
      {subNav && <SubNavBar translucent />}
      <Grid
        container
        direction="column"
        justifyContent="center"
        // 중앙 정렬을 위한 조정
        // subNavBar 높이 { xs: '30px', md: '40px', lg: '44px', xl: '48px' }
        sx={[
          subNav
            ? { height: { xs: '135px', md: '180px', lg: '206px', xl: '227px' } }
            : { height: { xs: '135px', md: '220px', lg: '250px', xl: '275px' } },
        ]}
      >
        <BannerTitle color={titleColor} sx={{ mt: { lg: '-4px' } }}>
          {titleText}
        </BannerTitle>
        <Typography
          color={titleColor}
          fontSize={{ xs: 10, lg: 16 }}
          sx={{ whiteSpace: 'pre-wrap', mt: { xs: '3px', lg: '6px' }, px: { xs: '20px' } }}
        >
          {subText}
        </Typography>
      </Grid>
    </Container>
  );
}

FirstSecionAddSubTitle.defaultProps = {
  backImg: '',
  subNav: false,
  titleText: '타이틀 텍스트를 입력해주세요',
  subText: '서브 텍스트를 입력해주세요',
  titleColor: '#FFF',
};

FirstSecionAddSubTitle.propTypes = {
  backImg: PropTypes.string,
  subNav: PropTypes.bool,
  titleText: PropTypes.string,
  subText: PropTypes.string,
  titleColor: PropTypes.string,
};

/** (어드민) 타이틀만 있는 섹션 */
export function AdminFirstSecion({ titleText }) {
  const navigate = useNavigate();

  const goToAdminGate = () => {
    navigate('/Admin');
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        py: '50px',
        backgroundColor: '#10263B',
      }}
    >
      <BannerTitle color="#FFF">{titleText}</BannerTitle>
      <Button onClick={goToAdminGate} color="primary" sx={{ mt: '20px' }}>
        어드민 메뉴로 돌아가기
      </Button>
    </Container>
  );
}

AdminFirstSecion.defaultProps = {
  titleText: '타이틀 텍스트를 입력해주세요',
};

AdminFirstSecion.propTypes = {
  titleText: PropTypes.string,
};
