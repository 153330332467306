import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardMedia, Container, Stack, Typography } from '@mui/material';
import ArrowRightIcon from '../../assets/arrowRight.png';
import HomeIcon from '../../assets/home.png';
import { FixedContainer } from './ContainerStyle';

/** 현재 위치를 알려주는 회색 바 */
export default function GrayPositionBar({ positionText1, positionText2 }) {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ backgroundColor: '#F9F9F9', display: { xs: 'none', md: 'block' } }}
    >
      <FixedContainer>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing="9px"
          sx={{
            height: { xs: '30px', md: '40px', lg: '44px', xl: '48px' },
          }}
        >
          <CardMedia
            component="img"
            image={HomeIcon}
            alt="home"
            onClick={goToHome}
            sx={{ width: '16.8px', height: '13.8px', cursor: 'pointer' }}
          />
          <CardMedia
            component="img"
            image={ArrowRightIcon}
            alt="arrow"
            sx={{ width: '5.14px', height: '7.7px' }}
          />
          <Typography>{positionText1}</Typography>
          {positionText2 && (
            <>
              <CardMedia
                component="img"
                image={ArrowRightIcon}
                alt="home"
                sx={{ width: '5.14px', height: '7.7px' }}
              />
              <Typography fontWeight={700}>{positionText2}</Typography>
            </>
          )}
        </Stack>
      </FixedContainer>
    </Container>
  );
}

GrayPositionBar.defaultProps = {
  positionText1: '',
  positionText2: '',
};

GrayPositionBar.propTypes = {
  positionText1: PropTypes.string,
  positionText2: PropTypes.string,
};
