import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, IconButton, Box, TextField, ThemeProvider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { BodyTitle, BodySubTitle } from './TextStyle';
import TextfieldTheme from './FilledTextFieldStyle';

/** 양끝 정렬된 바디 타이틀 & 서브텍스트(전체보기 등) */
export function BodyTitleWithSub({ mainTitle, subTitle, onClickSub }) {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <BodyTitle>{mainTitle}</BodyTitle>
      <BodySubTitle onClick={onClickSub} sx={{ cursor: 'pointer' }}>
        {subTitle}
      </BodySubTitle>
    </Grid>
  );
}

BodyTitleWithSub.defaultProps = {
  mainTitle: '',
  subTitle: '',
  onClickSub: () => {},
};

BodyTitleWithSub.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  onClickSub: PropTypes.func,
};

/** 바디 타이틀, 검색 */
export function BodyTitleWithSearch({
  mainTitle,
  searchInput,
  setSearchInput,
  refreshSwitch,
  setRefreshSwtich,
}) {
  const onChangeSearch = e => {
    setSearchInput(e.target.value);
  };

  const searchData = () => {
    setRefreshSwtich(!refreshSwitch);
  };

  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      searchData();
    }
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <BodyTitle>{mainTitle}</BodyTitle>
        <ThemeProvider theme={TextfieldTheme}>
          <TextField
            value={searchInput}
            onChange={onChangeSearch}
            onKeyPress={onCheckEnter}
            placeholder="검색어를 입력해주세요"
            sx={{ border: '0' }}
            InputProps={{
              sx: {
                borderRadius: '999px',
                pl: { xs: '7px', lg: '11px' },
                height: { xs: '30px', lg: '40px' },
                width: { xs: '155px', lg: '180px' },
                fontSize: { xs: '12px', lg: '14px' },
                color: '#666666',
              },
              startAdornment: (
                <IconButton onClick={searchData} sx={{ mr: '1px', p: 0.5 }}>
                  <SearchIcon sx={{ fontSize: { xs: '15px', lg: '18px' } }} />
                </IconButton>
              ),
            }}
          />
        </ThemeProvider>
      </Grid>
      <Box sx={{ backgroundColor: '#DADADA', height: '1px', mt: '12px', mb: '20px' }} />
    </div>
  );
}

BodyTitleWithSearch.defaultProps = {
  mainTitle: '',
  searchInput: '',
  setSearchInput: () => {},
  refreshSwitch: true,
  setRefreshSwtich: () => {},
};

BodyTitleWithSearch.propTypes = {
  mainTitle: PropTypes.string,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func,
  refreshSwitch: PropTypes.bool,
  setRefreshSwtich: PropTypes.func,
};

/** 바디 타이틀, 필터, 검색 */
export function BodyTitleWithFilterSearch({ mainTitle }) {
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={{ xs: '8px', lg: '20px' }}
        >
          <BodyTitle>{mainTitle}</BodyTitle>
          <BodySubTitle sx={{ cursor: 'pointer' }}>신간순</BodySubTitle>
          <BodySubTitle sx={{ cursor: 'pointer' }}>제목순</BodySubTitle>
        </Stack>
        <ThemeProvider theme={TextfieldTheme}>
          <TextField
            placeholder="검색어를 입력해주세요"
            sx={{ border: '0' }}
            InputProps={{
              sx: {
                borderRadius: '999px',
                pl: { xs: '7px', lg: '11px' },
                height: { xs: '30px', lg: '40px' },
                width: { xs: '155px', lg: '180px' },
                fontSize: { xs: '12px', lg: '14px' },
                color: '#666666',
              },
              startAdornment: (
                <IconButton sx={{ mr: '1px', p: 0.5 }}>
                  <SearchIcon sx={{ fontSize: { xs: '15px', lg: '18px' } }} />
                </IconButton>
              ),
            }}
          />
        </ThemeProvider>
      </Grid>
      <Box
        sx={{
          backgroundColor: '#DADADA',
          height: { xs: '0.5px', lg: '1px' },
          mt: { xs: '8px', lg: '12px' },
          mb: { xs: '14px', lg: '20px' },
        }}
      />
    </div>
  );
}

BodyTitleWithFilterSearch.defaultProps = {
  mainTitle: '',
};

BodyTitleWithFilterSearch.propTypes = {
  mainTitle: PropTypes.string,
};
