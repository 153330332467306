/** api 통신 주소 */
export const url =
  process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:9988' : 'https://api.thelinebooks.com';

/** itemNumber 글로벌 변수 */
export const itemNumber = 12;
export const noticeItemNumber = 7;
export const youtubeItemNumber = 9;
export const bookItemNumber = 12;
export const lectureItemNumber = 12;

/** 배너 비율 */
export const bannerRatioPC = '1920 / 372';
export const bannerRatioMob = '140 / 100';

/** 마감 임박 기간 */
export const lectureDeadLinePeriod = 14;
