import React from 'react';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import YoutubeSection from '../../../component/UI/YoutubeSection';
import TLSFirstSection from './TLSFirstSection';

export default function TLSDownload() {
  return (
    <div>
      <TLSFirstSection />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="TLS 다운로드" />
      <YoutubeSection company="academy" category="TLS 사용법 예시" />
    </div>
  );
}
