import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import NewList from './NewList';

export default function New() {
  return (
    <div>
      <FirstSecionSimple
        titleText="신간 도서"
        titleColor="#333333"
        subNav
        backImg="Books/new_background.png"
      />
      <NewList />
    </div>
  );
}
