import React from 'react';
import { Box } from '@mui/material';
import SubNavBar from '../../../component/NavBar/SubNavBar';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import YoutubeCarouselSection from '../../../component/UI/YoutubeCarouselSection';
import BookParts from './BookParts';
import MainBanner from '../../../component/UI/MainBanner';

export default function BooksMain() {
  return (
    <div>
      <SubNavBar />

      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <MainBanner company="books" category="북스 배너" device="PC" isMobile={false} />
      </Box>
      <Box sx={{ display: { lg: 'none' } }}>
        <MainBanner company="books" category="북스 배너" device="Mob" isMobile />
      </Box>

      <BodyContainer>
        <BookParts category="단행본" allLink="/Books/Monograph" />

        <BookParts category="전자책" allLink="/Books/E-Book" />
        <YoutubeCarouselSection
          title="북트레일러"
          company="books"
          category="북트레일러"
          allLink="/Books/BookTrailer"
        />

        <Box sx={{ height: '70px' }} />

        <YoutubeCarouselSection
          title="저자 인터뷰"
          company="books"
          category="저자 인터뷰"
          allLink="/Books/Interview"
        />
      </BodyContainer>
    </div>
  );
}
