import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BodyContainer } from './ContainerStyle';
import { BodyTitleWithSearch } from './BodyTitleSection';
import YoutubeList from './YoutubeList';
import Pagination from '../Pagination';
import { youtubeHook } from '../../module/useEffectHooks';

export default function YoutubeSection({ company, category }) {
  const [searchInput, setSearchInput] = useState('');
  const [refreshSwitch, setRefreshSwtich] = useState(true);
  const [youtubeList, page, setPage, totalItem, youtubeItemNumber] = youtubeHook(
    company,
    category,
    searchInput,
    refreshSwitch,
  );

  return (
    <BodyContainer>
      <BodyTitleWithSearch
        mainTitle={category}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        refreshSwitch={refreshSwitch}
        setRefreshSwtich={setRefreshSwtich}
      />
      <YoutubeList youtubeList={youtubeList} />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={youtubeItemNumber} />
    </BodyContainer>
  );
}

YoutubeSection.defaultProps = {
  company: '회사명을 입력해주세요',
  category: '카테고리를 입력해주세요',
};

YoutubeSection.propTypes = {
  company: PropTypes.string,
  category: PropTypes.string,
};
