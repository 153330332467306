/* eslint-disable */
import { createContext, useState } from 'react';

const LoginContext = createContext({
  isLogin: false,
  loginProcess: () => {},
});

const LoginProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);

  const loginProcess = () => {
    setIsLogin(true);
  };

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        loginProcess,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
