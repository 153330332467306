import React from 'react';
import { Box, Container, CardMedia, Typography, Grid, Stack } from '@mui/material';
import naverLogo from '../../assets/naver.png';
import instaLogo from '../../assets/insta.png';
import youtubeLogo from '../../assets/youtube.png';
import thelineBooks from '../../assets/thelineBooks.png';
import { FixedContainer } from '../UI/ContainerStyle';

function Footer() {
  const linkList = [
    {
      label: 'youtube',
      img: youtubeLogo,
      link: 'https://www.youtube.com/channel/UCeE3VUCbysuJfjdQgzXHQ-g',
    },
    { label: 'naver', img: naverLogo, link: 'https://cafe.naver.com/thelineopencare' },
    { label: 'insta', img: instaLogo, link: 'https://www.instagram.com/thelinebooks' },
  ];

  const openURL = url => {
    window.open(url);
  };

  return (
    <footer>
      <Container maxWidth={false} disableGutters sx={{ backgroundColor: '#F8F9FE' }}>
        <FixedContainer>
          <Grid
            container
            alignItems="center"
            sx={{ height: { xs: '210px', lg: '350px', xl: '377px' } }}
          >
            <Box sx={{ width: 1 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: { xs: '15px', lg: '20px' } }}
              >
                <CardMedia
                  component="img"
                  image={thelineBooks}
                  alt="theline Logo"
                  sx={{ width: { xs: '100px', md: '134px' } }}
                />
                <Box>
                  <Stack direction="row" spacing="10px">
                    {linkList.map(function (each) {
                      return (
                        <div key={`더라인 ${each.label} 채널`}>
                          <CardMedia
                            component="img"
                            image={each.img}
                            alt={`더라인 ${each.label} 채널`}
                            onClick={() => openURL(each.link)}
                            sx={{ width: { xs: '30px', md: '40px' }, cursor: 'pointer' }}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </Box>
              </Grid>

              <Typography
                component="div"
                color="#666666"
                fontSize={{ xs: '10px', lg: '15px', xl: '16px' }}
                fontWeight={300}
                align="left"
              >
                <Box>상호: 더라인북스 | 대표자: 함혜숙 | 사업자번호: 350-90-00624</Box>
                <Box>주소: 서울 구로구 디지털로30길 31 코오롱디지털타워빌란트2 1206호</Box>
                <Box sx={{ mt: '10px' }}>© The Line Books. All rights reserved.</Box>
              </Typography>
            </Box>
          </Grid>
        </FixedContainer>
      </Container>
    </footer>
  );
}

export default Footer;
