import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Modal, TextField, Stack, Button, Box, CardMedia, ButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import ConfirmModal from '../ConfirmModal';
import { url, bannerRatioPC, bannerRatioMob } from '../../commonVariable';

export default function AddEditBannerModal({
  modalSwitch,
  setModalSwitch,
  editData,
  refreshSwitch,
  setRefreshSwitch,
  whichCompany,
  category,
}) {
  // PC or Mob 배너
  const [device, setDevice] = useState(editData.device || 'PC');

  // 파일 추가
  const [newImage, setNewImage] = useState({ name: '' });
  const fileInput = useRef();

  // edit 인풋
  const editField = [
    { label: '링크 (클릭 시)', keyName: 'link', width: 0.7 },
    { label: '순서', keyName: 'bannerOrder', width: 0.15, addText: '* 높은 순으로 배치됩니다' },
  ];
  const [editInput, setEditInput] = useState(JSON.parse(JSON.stringify(editData)));

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const changeOption = whichDevice => {
    setDevice(whichDevice);
  };

  const addFile = () => {
    fileInput.current.click();
  };

  // 올라간 이미지
  const onChangeImage = e => {
    setNewImage(e.target.files[0]);
    encodeFileToBase64(e.target.files[0]);
  };

  // 보이게 하는 로직
  const encodeFileToBase64 = fileBlob => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);

    return new Promise(resolve => {
      reader.onload = () => {
        const tempObj = { ...editInput };
        tempObj.img = reader.result;
        setEditInput(tempObj);
        resolve();
      };
    });
  };

  const onChangeEditInput = e => {
    const { name, value } = e.target;
    setEditInput({
      ...editInput,
      [name]: value,
    });
  };

  const modalClose = () => {
    setModalSwitch(false);
  };

  const saveData = () => {
    if (Object.keys(editData).length === 0 && newImage.name === '') {
      alert('배너 이미지를 업로드해주세요.');
    } else if (!editInput.link) {
      alert('링크를 입력해주세요.');
    } else if (!editInput.bannerOrder) {
      alert('순서를 입력해주세요.');
    } else {
      const formData = new FormData();
      // 수정인 경우,bannerId 추가
      if (Object.keys(editData).length !== 0) {
        formData.append('bannerId', editInput.bannerId);
      }
      formData.append('fileName', newImage.name);
      formData.append('link', editInput.link);
      formData.append('bannerOrder', editInput.bannerOrder);
      formData.append('category', category);
      formData.append('device', device);
      formData.append('directory', 'Banner');
      formData.append('file', newImage); // formData는 키-밸류 구조

      const mode = Object.keys(editData).length === 0 ? 'add' : 'edit';

      axios.post(`${url}/admin/${whichCompany}/banner/${mode}`, formData).then(() => {
        setRefreshSwitch(!refreshSwitch);
        alert('정상적으로 완료되었습니다');
        modalClose();
      });
    }
  };

  const openDeleteModal = () => {
    setDeleteConfirmModal(true);
  };

  const deleteBanner = () => {
    axios.post(`${url}/admin/${whichCompany}/banner/delete/${editData.bannerId}`).then(() => {
      setRefreshSwitch(!refreshSwitch);
      alert('삭제가 완료되었습니다.');
      modalClose();
    });
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 700,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 40px 45px 40px',
            outline: 'none',
          }}
        >
          <ButtonGroup sx={{ mb: '20px' }}>
            <Button
              onClick={() => changeOption('PC')}
              variant={device === 'PC' ? 'contained' : 'outlined'}
            >
              PC
            </Button>
            <Button
              onClick={() => changeOption('Mob')}
              variant={device === 'Mob' ? 'contained' : 'outlined'}
            >
              Mob
            </Button>
          </ButtonGroup>
          <input type="file" hidden onChange={onChangeImage} ref={fileInput} accept="image/*" />
          <CardMedia
            component="img"
            image={editInput.img}
            onClick={addFile}
            sx={[
              device === 'PC'
                ? { width: '600px', aspectRatio: bannerRatioPC }
                : { width: '330px', aspectRatio: bannerRatioMob },
              {
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.7,
                },
                mb: '20px',
              },
            ]}
          />
          {editField.map(function (each) {
            return (
              <Stack
                key={each.label}
                direction="row"
                spacing={3}
                alignItems="center"
                sx={{ mb: '10px', width: 1 }}
              >
                <Box sx={{ width: '120px' }}>
                  <h4>{each.label}</h4>
                </Box>
                <TextField
                  name={each.keyName}
                  value={editInput[each.keyName] || ''}
                  onChange={onChangeEditInput}
                  type={each.label === '순서' ? 'number' : null}
                  sx={{ width: each.width, mr: '30px' }}
                />
                {each.addText && <Box>{each.addText}</Box>}
              </Stack>
            );
          })}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={saveData}
              sx={{ color: 'white', mr: '30px', width: '100px' }}
            >
              {Object.keys(editData).length === 0 ? '추가하기' : '수정하기'}
            </Button>
            {Object.keys(editData).length !== 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={openDeleteModal}
                sx={{ width: '100px', mr: '30px' }}
              >
                삭제하기
              </Button>
            )}
            <Button variant="contained" color="grey" onClick={modalClose} sx={{ width: '100px' }}>
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>

      {deleteConfirmModal && (
        <ConfirmModal
          modalSwitch={deleteConfirmModal}
          setModalSwitch={setDeleteConfirmModal}
          title="삭제 확인"
          contents="정말 해당 배너를 삭제하시겠습니까?"
          func={deleteBanner}
        />
      )}
    </div>
  );
}

AddEditBannerModal.defaultProps = {
  editData: {},
  modalSwitch: true,
  setModalSwitch: () => {},
  refreshSwitch: true,
  setRefreshSwitch: () => {},
  whichCompany: 'books || Academy',
  category: '어떤 유튜브인지 입력해주세요',
};

AddEditBannerModal.propTypes = {
  // eslint-disable-next-line
  editData: PropTypes.object,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
  whichCompany: PropTypes.string,
  category: PropTypes.string,
};
