import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Grid, Button } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from '../TextStyle';
import { changeDateDash } from '../../commonFunction';

/** 유튜브 리스트 UI */
export default function AdminYoutubeList({ youtubeList, adminFunc }) {
  const openYoutube = youtubeLink => {
    window.open(youtubeLink);
  };
  return (
    <Grid container spacing={{ lg: '50px' }}>
      {youtubeList.map(function (each) {
        let img = '';
        if (each.link.includes('.be')) {
          img = each.link && `https://i.ytimg.com/vi/${each.link.split('/')[3]}/maxresdefault.jpg`;
        } else {
          img = each.link && `https://i.ytimg.com/vi/${each.link.split('=')[1]}/maxresdefault.jpg`;
        }

        return (
          <Grid item xs={6} key={each.youtubeId}>
            <CardMedia
              component="img"
              alt={`youtube Image${each.title}`}
              image={img && img}
              onClick={() => openYoutube(each.link)}
              sx={{
                width: { xs: '360px', lg: '516px' },
                height: { xs: '220px', lg: '290px' },
                borderRadius: '12px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.85,
                },
              }}
            />
            <NormalContentsTitle sx={{ mt: '10px' }}>{each.title}</NormalContentsTitle>
            <NormalContentsSubTitle>{each.subTitle}</NormalContentsSubTitle>
            <Grid container justifyContent="space-between" sx={{ mt: '10px' }}>
              <Button variant="outlined" onClick={() => adminFunc(each)}>
                수정하기
              </Button>
              {`작성일 : ${changeDateDash(each.createdAt)}`}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

AdminYoutubeList.defaultProps = {
  youtubeList: [],
  adminFunc: () => {},
};

AdminYoutubeList.propTypes = {
  // eslint-disable-next-line
  youtubeList: PropTypes.array,
  adminFunc: PropTypes.func,
};
