import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import { url } from '../../../component/commonVariable';
import QuillEditorViewer from '../../../component/UI/QuillEditorViewer';
import OpenCare from './OpenCare';

export default function LectureDescription() {
  const { category, lectureId } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const introSection = useRef();
  const teacherSection = useRef();
  const curriculumSection = useRef();
  const enrollSection = useRef();
  const reviewSection = useRef();

  const [editQuillIntro, setEditQuillIntro] = useState('');
  const [editQuillTeacher, setEditQuillITeacher] = useState('');
  const [editQuillCurriculum, setEditQuillCurriculum] = useState('');
  const [editQuillEnroll, setEditQuillEnroll] = useState('');
  const [editQuillReview, setEditQuillReview] = useState('');

  const defaultField = [
    {
      label: category === '특강' ? '특강안내' : '강의안내',
      data: editQuillIntro,
      setData: setEditQuillIntro,
      ref: introSection,
    },
    // eslint-disable-next-line
    { label: '강사소개', data: editQuillTeacher, setData: setEditQuillITeacher, ref: teacherSection },
    // eslint-disable-next-line
    { label: '커리큘럼', data: editQuillCurriculum, setData: setEditQuillCurriculum, ref: curriculumSection },
    { label: '수강신청', data: editQuillEnroll, setData: setEditQuillEnroll, ref: enrollSection },
    { label: '수강후기', data: editQuillReview, setData: setEditQuillReview, ref: reviewSection },
  ];

  const editorField =
    category === '특강'
      ? defaultField.filter(e => e.label !== '커리큘럼' && e.label !== '수강후기')
      : defaultField;

  useEffect(() => {
    axios.get(`${url}/academy/lecture/getDetail/editor/${lectureId}`).then(result => {
      // console.log(result.data);
      if (result.data.length !== 0) {
        // 데이터 받아서 각 editor에 쏴주는
        editorField.forEach(function (each) {
          if (each.label === '특강안내') {
            each.setData(result.data.filter(e => e.category === '강의안내')[0].contents);
          } else {
            each.setData(result.data.filter(e => e.category === each.label)[0].contents);
          }
        });
      }
    });
  }, []);

  // 스크롤 이벤트 등록
  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    };
  }, []);

  const goToSection = ref => {
    const topOfElement = ref.current.offsetTop - 200;
    window.scroll({ top: topOfElement, behavior: 'smooth' });
  };

  // 스크롤 위치에 따라 탭 활성화
  const handleFollow = () => {
    if (category === '특강') {
      if (window.scrollY > enrollSection.current.offsetTop - 250) {
        setSelectedTab(2);
      } else if (window.scrollY > teacherSection.current.offsetTop - 250) {
        setSelectedTab(1);
      } else if (window.scrollY > introSection.current.offsetTop - 250) {
        setSelectedTab(0);
      }
    } else {
      // eslint-disable-next-line
      if (window.scrollY > reviewSection.current.offsetTop - 250) {
        setSelectedTab(4);
      } else if (window.scrollY > enrollSection.current.offsetTop - 250) {
        setSelectedTab(3);
      } else if (window.scrollY > curriculumSection.current.offsetTop - 250) {
        setSelectedTab(2);
      } else if (window.scrollY > teacherSection.current.offsetTop - 250) {
        setSelectedTab(1);
      } else if (window.scrollY > introSection.current.offsetTop - 250) {
        setSelectedTab(0);
      }
    }
  };

  return (
    <div>
      <Tabs
        value={editorField[selectedTab].label}
        textColor="inherit"
        variant="fullWidth"
        sx={{
          mt: { xs: '44px', lg: '84px' },
          position: 'sticky',
          zIndex: 110,
          top: { xs: '54px', lg: '104px', xl: '120px' },
        }}
        TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
      >
        {editorField.map(function (each, index) {
          return (
            <Tab
              onClick={() => goToSection(each.ref)}
              key={each.label}
              value={each.label}
              label={each.label}
              sx={[
                selectedTab === index
                  ? { backgroundColor: '#FFF', border: '2px solid #F5F5F5' }
                  : { backgroundColor: '#F5F5F5', color: '#999999' },
                {
                  fontSize: { xs: '14px', lg: '20px' },
                  fontWeight: 500,
                  p: { xs: 0, lg: '' },
                  minWidth: { xs: '20px', lg: '' },
                  minHeight: { xs: '40px', lg: '48px' },
                  zIndex: 100,
                  opacity: 1,
                },
              ]}
            />
          );
        })}
      </Tabs>

      <Box sx={{ px: { lg: '30px' }, py: '40px' }}>
        {editorField.map(function (each, index) {
          return (
            <Box
              ref={each.ref}
              key={`${each.label} 소개내용`}
              sx={[
                index === 4 || (index === 3 && category === '특강')
                  ? null
                  : { borderBottom: '3px solid #F6F8FF' },
                {
                  mt: '18px',
                  mb: '60px',
                },
              ]}
            >
              <Box
                sx={{
                  width: '24px',
                  height: { xs: '6px', lg: '6px' },
                  backgroundColor: '#161F2C',
                  mb: '20px',
                  ml: '15px',
                }}
              />
              <QuillEditorViewer contents={each.data} />
            </Box>
          );
        })}
      </Box>

      <OpenCare />
    </div>
  );
}
