import React from 'react';
import { Button, CardMedia, Stack, Typography } from '@mui/material';
import { TheLineBodyContainer } from '../../../component/UI/ContainerStyle';
import { BodyTitle } from '../../../component/UI/TextStyle';
import { openWindow } from '../../../component/commonFunction';

export default function CommunityIntro() {
  return (
    <TheLineBodyContainer>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="11px">
        <BodyTitle>커뮤니티</BodyTitle>
        <BodyTitle color="primary" sx={{ fontWeight: 500 }}>
          더라인 번역 오픈케어
        </BodyTitle>
      </Stack>

      {/* mob 이미지 */}
      <CardMedia
        component="img"
        alt="opencare banner"
        image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/opencare_banner_mob.png"
        onClick={() => openWindow('https://cafe.naver.com/thelineopencare')}
        sx={{
          mt: { xs: '12px' },
          mb: { xs: '18px' },
          width: { xs: 1 },
          display: { lg: 'none' },
          cursor: 'pointer',
          ':hover': {
            opacity: 0.8,
          },
        }}
      />

      {/* PC 이미지 */}
      <CardMedia
        component="img"
        alt="opencare banner"
        image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/opencare_banner.png"
        onClick={() => openWindow('https://cafe.naver.com/thelineopencare')}
        sx={{
          mt: { lg: '20px', xl: '21px' },
          mb: { lg: '24px', xl: '24px' },
          width: { lg: '1080px' },
          height: { lg: '280px' },
          display: { xs: 'none', lg: 'block' },
          cursor: 'pointer',
          ':hover': {
            opacity: 0.8,
          },
        }}
      />

      <Typography align="left" fontSize={{ xs: 14, lg: 18, xl: 20 }} lineHeight={1.6}>
        <b>
          더라인 번역 오픈케어 카페는 번역과 글쓰기에 대한 정보를 오픈(OPEN)하고 실력을
          케어(CARE)해주는 온라인 스터디 공간입니다.
        </b>
        <br />
        번역과 글쓰기에 관심이 있다면 누구나 자유롭게 참여할 수 있습니다.
        <br />
        <br />
        영상번역 강의, 특강, 북클럽, 원서 클럽, 중드 클럽 등 다양한 활동 정보를 얻을 수 있습니다.
        <br />
        번역 근육 키우기, 출판 번역 맛보기, 영화 번역 맛보기, 미드 자막 없이 보기, 중드 자막 없이
        보기 등 현직 번역가가 운영하는 무료 온라인 스터디도 진행되고 있습니다.
      </Typography>

      <Button
        onClick={() => openWindow('https://cafe.naver.com/thelineopencare')}
        sx={{
          mt: { xs: '24px', lg: '70px', xl: '80px' },
          borderRadius: { xs: '8px', lg: '12px', xl: '16px' },
          fontSize: { xs: '16px', lg: '18px', xl: '20px' },
          width: { xs: 1, lg: '380px', xl: '400px' },
          height: { xs: '44px', lg: '50px', xl: '60px' },
        }}
      >
        카페 바로가기
      </Button>
    </TheLineBodyContainer>
  );
}
