import React from 'react';
import { FirstSecionAddSubTitle } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import LectureSection from '../../../component/UI/LectureSection';

export default function Online() {
  return (
    <div>
      <FirstSecionAddSubTitle
        titleText="온라인 강의"
        subText={`언제 어디서나 원하는 시간에 ‘네이버 밴드’ 공간에서 동영상 강의를 반복 학습하고
      번역 과제를 수행하며 현직 영상번역가인 강사의 피드백을 받을 수 있습니다.`}
        subNav
        backImg="Academy/online_background.png"
      />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="온라인 강의" />
      <LectureSection category="온라인 강의" />
    </div>
  );
}
