import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ko';
import { lectureDeadLinePeriod } from '../commonVariable';

/** 파란색 뱃지 (공지사항의 공내) */
export function CategoryBadge({ badgeText }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        sx={{
          backgroundColor: '#4891FF',
          borderRadius: '999px',
          px: { xs: '9px', lg: '12px', xl: '13px' },
          py: { xs: '3px', lg: '4px', xl: '5px' },
          fontWeight: 700,
          fontSize: { xs: '11px', lg: '15px', xl: '16px' },
          color: '#FFF',
          wordBreak: 'keep-all',
        }}
      >
        {badgeText}
      </Box>
    </Box>
  );
}

CategoryBadge.defaultProps = {
  badgeText: '뱃지',
};

CategoryBadge.propTypes = {
  badgeText: PropTypes.string,
};

/** 강의 뱃지 (모집중, 마감임박, 마감) */
export function LectureBadge({ endDate }) {
  const now = moment(new Date()).format('YYYYMMDD');
  const DeadLinePeriodFromNow = moment().add(lectureDeadLinePeriod, 'day').format('YYYYMMDD');

  let badge;
  if (now > endDate) {
    badge = { label: '마감', color: '#A8A8A8' };
  } else if (DeadLinePeriodFromNow > endDate) {
    badge = { label: '마감임박', color: '#EA187D' };
  } else {
    badge = { label: '모집중', color: '#4891FF' };
  }

  return (
    <Box
      sx={{
        mt: { xs: '6px', lg: '8px' },
        fontSize: { xs: '10px', lg: '12px' },
        color: '#FFF',
        fontWeight: 700,
        width: { xs: '52px', lg: '64px' },
        py: { xs: '4px', lg: '5px' },
        borderRadius: '4px',
        backgroundColor: badge.color,
      }}
    >
      {badge.label}
    </Box>
  );
}

LectureBadge.defaultProps = {
  endDate: '20220101',
};

LectureBadge.propTypes = {
  endDate: PropTypes.string,
};
