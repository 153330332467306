import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, CardMedia, Grid, Stack } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from './TextStyle';
import { LectureBadge } from './Badge';
import { changeDateKor } from '../commonFunction';

/** 강의 목록 UI */
export default function LectureList({ lectureList, isMain }) {
  const navigate = useNavigate();

  const goToDetail = (id, category) => {
    navigate(`/Academy/Lecture/${category}/${id}`);
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container spacing={{ xs: '20px', lg: '40px' }}>
      {lectureList.map(function (each) {
        return (
          <Grid item xs={6} lg={3} key={each.lectureId} sx={{ mb: { xs: '5px', lg: '0px' } }}>
            <CardMedia
              component="img"
              alt={`lecture Image-${each.title}`}
              image={each.img || ''}
              onClick={() => goToDetail(each.lectureId, each.category)}
              sx={{
                width: { xs: 1, lg: '240px' },
                aspectRatio: '1',
                cursor: 'pointer',
              }}
            />
            <Box
              onClick={() => goToDetail(each.lectureId, each.category)}
              sx={[
                each.isPublished === 0 && { backgroundColor: 'rgba(0,0,0, 0.4)' },
                { cursor: 'pointer' },
              ]}
            >
              <NormalContentsTitle
                sx={{ mt: { xs: '8px', lg: '14px' }, mb: { xs: '2px', lg: '3px' } }}
              >
                {each.title}
              </NormalContentsTitle>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={{ xs: '3px', lg: '5px' }}
              >
                <NormalContentsSubTitle sx={{ letterSpacing: { xs: '-0.03em', lg: '0em' } }}>
                  {changeDateKor(each.lectureStart)}
                </NormalContentsSubTitle>
                {each.lectureStartHour && (
                  <NormalContentsSubTitle sx={{ letterSpacing: { xs: '-0.03em', lg: '0em' } }}>
                    {`${each.lectureStartHour} 개강 `}
                  </NormalContentsSubTitle>
                )}
                <NormalContentsSubTitle sx={{ letterSpacing: { xs: '-0.03em', lg: '0em' } }}>
                  {each.lectureNum && `/ ${each.lectureNum}`}
                </NormalContentsSubTitle>
              </Stack>
            </Box>

            {!isMain && <LectureBadge endDate={each.enrollEnd} />}
          </Grid>
        );
      })}
    </Grid>
  );
}

LectureList.defaultProps = {
  lectureList: [],
  isMain: false,
};

LectureList.propTypes = {
  // eslint-disable-next-line
  lectureList: PropTypes.array,
  isMain: PropTypes.bool,
};
