import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CardMedia, Container, Grid, Stack, Typography } from '@mui/material';
import { BannerTitle } from '../../../component/UI/TextStyle';
import ArrowRightWhiteIcon from '../../../assets/arrowRightWhite.png';
import { backgroundURL } from '../../../component/commonFunction';

export default function MainGate() {
  const navigate = useNavigate();

  const goToUrl = link => {
    navigate(link);
  };

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundImage: backgroundURL('integrated/gate_background.png'),
        backgroundSize: 'cover',
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          height: { xs: '477px', md: '550px', lg: '570px', xl: '678px' },
        }}
      >
        <BannerTitle color="primary" sx={{ mb: { xs: '22px', lg: '24px', xl: '36px' } }}>
          더라인과 함께하세요!
        </BannerTitle>

        {/* 모바일 */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          sx={{ display: { md: 'none' } }}
        >
          <CardMedia
            component="img"
            image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/gate_books_mob.png"
            onClick={() => goToUrl('/Books')}
            alt="thelineBooks Mob"
            sx={{
              width: '343px',
              height: '157px',
              borderRadius: '16px 16px 0px 0px',
              mb: '10px',
            }}
          />
          <CardMedia
            component="img"
            image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/gate_academy_mob.png"
            onClick={() => goToUrl('/Academy')}
            alt="thelineAcademy Mob"
            sx={{
              width: '343px',
              height: '157px',
              borderRadius: '16px 16px 0px 0px',
            }}
          />
        </Grid>

        {/* PC 영역 */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing="25px">
            <Box
              onClick={() => goToUrl('/Books')}
              sx={{
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                borderRadius: '16px',
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.85,
                },
              }}
            >
              <CardMedia
                component="img"
                image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/gate_books.png"
                alt="booksBanner"
                sx={{
                  width: { md: '350px', xl: '408px' },
                  height: { md: '320px', xl: '373px' },
                  borderRadius: '16px 16px 0px 0px',
                }}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing="15px"
                sx={{
                  backgroundColor: '#4891FF',
                  borderRadius: '0px 0px 16px 16px',
                  py: { md: '14px', xl: '16px' },
                }}
              >
                <Typography fontSize={{ md: 18, xl: 20 }} fontWeight={700} color="#FFF">
                  바로가기
                </Typography>
                <CardMedia
                  component="img"
                  image={ArrowRightWhiteIcon}
                  alt="arrow"
                  sx={{ width: '8px', height: '14px' }}
                />
              </Stack>
            </Box>

            <Box
              onClick={() => goToUrl('/Academy')}
              sx={{
                borderRadius: '16px',
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.85,
                },
              }}
            >
              <CardMedia
                component="img"
                image="https://thelineimage.s3.ap-northeast-2.amazonaws.com/integrated/gate_academy.png"
                alt="academy banner"
                sx={{
                  width: { md: '350px', xl: '408px' },
                  height: { md: '320px', xl: '373px' },
                  borderRadius: '16px 16px 0px 0px',
                }}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing="15px"
                sx={{
                  backgroundColor: '#4891FF',
                  borderRadius: '0px 0px 16px 16px',
                  py: { md: '14px', xl: '16px' },
                }}
              >
                <Typography fontSize={{ md: 18, xl: 20 }} fontWeight={700} color="#FFF">
                  바로가기
                </Typography>
                <CardMedia
                  component="img"
                  image={ArrowRightWhiteIcon}
                  alt="arrow"
                  sx={{ width: '8px', height: '14px' }}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Container>
  );
}
