import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Table, TableRow, TableCell, TableBody, Button, Grid } from '@mui/material';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import QuillEditorViewer from '../../../component/UI/QuillEditorViewer';
import { url } from '../../../component/commonVariable';
import { TheLineBodyContainer } from '../../../component/UI/ContainerStyle';
import { changeDateDash } from '../../../component/commonFunction';

export default function LectureNoticeDetail() {
  const { noticeId } = useParams();
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    axios.get(`${url}/academy/Notice/getDetail/${noticeId}`).then(result => {
      setDetailData(result.data[0]);
    });
  }, [noticeId]);

  const goToBoard = () => {
    navigate('/Academy/LectureNews');
  };

  const goToOtherNotice = id => {
    navigate(`/Academy/Notice/${id}`);
  };

  return (
    <div>
      <FirstSecionSimple
        titleText="강의소식"
        backImg="integrated/notice_community_background.png"
        subNav
      />
      <TheLineBodyContainer>
        <Table sx={{ mb: '15px' }}>
          <TableBody>
            <TableRow sx={{ borderTop: { xs: '0.5px solid #C9C9C9', xl: '0.5px solid #C9C9C9' } }}>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: '#F7F7F7',
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  fontWeight: 700,
                  width: { lg: '80px' },
                  height: { lg: '20px' },
                }}
              >
                제목
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  pl: { xs: '10px', lg: '25px' },
                  width: { lg: '600px' },
                }}
              >
                {detailData.title}
              </TableCell>

              <TableCell
                align="center"
                sx={{
                  backgroundColor: '#F7F7F7',
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  fontWeight: 700,
                  width: { lg: '80px' },
                  height: { lg: '20px' },
                  display: { xs: 'none', lg: 'table-cell' },
                }}
              >
                날짜
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  pl: { xs: '10px', lg: '25px' },
                  display: { xs: 'none', lg: 'table-cell' },
                }}
              >
                {changeDateDash(detailData.createdAt)}
              </TableCell>
            </TableRow>

            {/* Mob 두 줄 표시를 위한 */}
            <TableRow
              sx={{
                borderTop: { lg: '0.5px solid #C9C9C9', xl: '0.5px solid #C9C9C9' },
                display: { lg: 'none' },
              }}
            >
              <TableCell
                align="center"
                sx={{
                  backgroundColor: '#F7F7F7',
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  fontWeight: 700,
                  width: { lg: '80px' },
                  height: { lg: '20px' },
                }}
              >
                날짜
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: { xs: '12px', lg: '19px', xl: '20px' },
                  pl: { xs: '10px', lg: '25px' },
                }}
              >
                {changeDateDash(detailData.createdAt)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box
          sx={{
            border: '0.7px solid #C9C9C9',
            borderRadius: '4px',
            px: { xs: '6px', lg: '10px' },
            py: { xs: '6px', lg: '10px' },
          }}
        >
          <QuillEditorViewer contents={detailData.contents || ''} />
        </Box>

        <Grid container direction="row" justifyContent="space-between" sx={{ mt: '20px' }}>
          <Box>
            <Button
              onClick={() => goToOtherNotice(detailData.prev)}
              variant="outlined"
              disabled={!detailData.prev}
            >
              이전으로
            </Button>
            <Button
              onClick={() => goToOtherNotice(detailData.next)}
              variant="outlined"
              disabled={!detailData.next}
              sx={{ ml: '5px' }}
            >
              다음으로
            </Button>
          </Box>

          <Button onClick={goToBoard}>목록으로 돌아가기</Button>
        </Grid>
      </TheLineBodyContainer>
    </div>
  );
}
