import { Typography } from '@mui/material';
import { styled } from '@mui/system';

/** 배너 타이틀 텍스트(페이지 제목 등(공지사항, 커뮤니티, 신간도서 등) */
export const BannerTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'GmarketSans',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '36px',
  },
}));

/** 바디 타이틀 텍스트(모집 중 강의 등등 바디 내 타이틀) */
export const BodyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '26px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '28px',
  },
}));

/** 서브 타이틀 & 필터 버튼 텍스트(전체보기, 신간순, 제목순 등) */
export const BodySubTitle = styled(Typography)(({ theme }) => ({
  color: '#666666',
  fontWeight: 500,
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  },
}));

/** 일반 콘텐츠 타이틀 (유튜브 영상 제목, 책 제목 등) / 조금 더 큰 것은 제외 */
export const NormalContentsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: '15px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '20px',
  },
}));

/** 일반 콘텐츠 서브 타이틀 (유튜브 영상 부제, 책 부제 등) */
export const NormalContentsSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: '#666666',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  },
}));
