/* eslint-disable */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import './App.css';
import Footer from './component/Footer';
import NavBar from './component/NavBar';
import TheLineMain from './page/theline/Main';
import TheLineNotice from './page/theline/Notice';
import TheLineCommunity from './page/theline/Community';
import TheLineContact from './page/theline/Contact';
import BooksMain from './page/books/Main';
import AcademyMain from './page/academy/Main';
import BookTrailer from './page/books/BookTrailer';
import Interview from './page/books/Interview';
import EBook from './page/books/E-Book';
import Monograph from './page/books/Monograph';
import New from './page/books/New';
import BooksDetail from './page/books/BooksDetail';
import LectureNews from './page/academy/LectureNews';
import Video from './page/academy/Video';
import Online from './page/academy/Online';
import Offline from './page/academy/Offline';
import SpecialLecture from './page/academy/SpecialLecture';
import TLSDownload from './page/academy/TLSDownload';
import LectureDetail from './page/academy/LectureDetail';
import AdminGate from './page/admin/adminGate';
import AdminTheLine from './page/admin/theline';
import EditNotice from './page/admin/theline/EditNotice';
import TheLineNoticeDetail from './page/theline/TheLineNoticeDetail/TheLineNoticeDetail';
import AdminBooks from './page/admin/books';
import AdminAcademy from './page/admin/academy';
import EditBook from './page/admin/books/EditBook';
import EditLecture from './page/admin/academy/EditLecture';
import EditLectureNotice from './page/admin/academy/EditLectureNotice';
import LectureNoticeDetail from './page/academy/LectureNoticeDetail';
import Entrance from './page/admin/Entrance';
import SEOHelmet from './module/SEOHelmet';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Box sx={{ height: { xs: '54px', lg: '60px', xl: '72px' } }} />
      <Routes>
        {/* 더라인 통합 페이지 */}
        <Route path="/" element={<><TheLineMain /> <SEOHelmet /></>} />
        <Route path="/Notice" element={<><TheLineNotice /><SEOHelmet title="공지사항" /></>} />
        <Route path="/Notice/:noticeId" element={<><TheLineNoticeDetail /> <SEOHelmet title="공지사항" /></>} />
        <Route path="/Community" element={<><TheLineCommunity /> <SEOHelmet title="커뮤니티"/> </>} />
        <Route path="/Contact" element={<><TheLineContact /> <SEOHelmet title="찾아오는길" /> </>} />

        {/* 더라인북스 */}
        <Route path="/Books" element={<><BooksMain /> <SEOHelmet title="더라인북스" /> </>} />
        <Route path="/Books/New" element={<><New /> <SEOHelmet title="신간 - 더라인북스" /> </>} />
        <Route path="/Books/Monograph" element={<><Monograph /> <SEOHelmet title="단행본 - 더라인북스" /> </>} />
        <Route path="/Books/E-Book" element={<><EBook /> <SEOHelmet title="전자책 - 더라인북스" /> </>} />
        <Route path="/Books/Book/:category/:bookId" element={<><BooksDetail /> <SEOHelmet title="책 상세 - 더라인북스" /> </>} />
        <Route path="/Books/BookTrailer" element={<><BookTrailer /> <SEOHelmet title="북트레일러 - 더라인북스" /> </>} />
        <Route path="/Books/Interview" element={<><Interview /> <SEOHelmet title="인터뷰 - 더라인북스" /> </>} />

        {/* 더라인아카데미 */}
        <Route path="/Academy" element={<><AcademyMain /> <SEOHelmet title="더라인아카데미" /> </>} />
        <Route path="/Academy/Notice/:noticeId" element={<><LectureNoticeDetail /> <SEOHelmet title="강의소식 - 더라인아카데미" /> </>} />
        <Route path="/Academy/Online" element={<><Online /> <SEOHelmet title="온라인 강의 - 더라인아카데미" /> </>} />
        <Route path="/Academy/Offline" element={<><Offline /> <SEOHelmet title="오프라인 강의 - 더라인아카데미" /> </>} />
        <Route path="/Academy/SpecialLecture" element={<><SpecialLecture /> <SEOHelmet title="특강 - 더라인아카데미" /> </>} />
        <Route path="/Academy/LectureNews" element={<><LectureNews /> <SEOHelmet title="강의소식 - 더라인아카데미" /> </>} />
        <Route path="/Academy/Lecture/:category/:lectureId" element={<><LectureDetail /> <SEOHelmet title="강의소식 - 더라인아카데미"/> </>} />
        <Route path="/Academy/Video" element={<><Video /> <SEOHelmet title="동영상 강의 - 더라인아카데미"/> </>} />
        <Route path="/Academy/TLS-Download" element={<><TLSDownload /> <SEOHelmet title="TLS 다운로드 - 더라인아카데미" /> </>} />

        {/* 어드민 */}
        <Route path="/Login" element={<Entrance />} />
        <Route path="/Admin" element={<AdminGate />} />
        <Route path="/Admin/TheLine" element={<AdminTheLine />} />
        <Route path="/Admin/TheLine/EditNotice/:noticeId" element={<EditNotice />} />

        <Route path="/Admin/Books" element={<AdminBooks />} />
        <Route path="/Admin/Books/EditBook/:category/:bookId" element={<EditBook />} />

        <Route path="/Admin/Academy" element={<AdminAcademy />} />
        <Route path="/Admin/Academy/EditLecture/:category/:lectureId" element={<EditLecture />} />
        <Route path="/Admin/Academy/EditNotice/:noticeId" element={<EditLectureNotice />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
