import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export default function SEOHelmet({ title, canonicalLink, description }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <link rel="canonical" href={canonicalLink} />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
    </div>
  );
}

SEOHelmet.defaultProps = {
  title: '더라인북스 & 더라인아카데미',
  canonicalLink: 'https://thelinebooks.com',
  description: '더라인 - 책과 영화, 번역, 세상을 하나로 잇는 선',
};

SEOHelmet.propTypes = {
  title: PropTypes.string,
  canonicalLink: PropTypes.string,
  description: PropTypes.string,
};
