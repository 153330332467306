import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import { BodyTitle } from '../../../component/UI/TextStyle';
import BookList from '../../../component/UI/BookList';
import { url } from '../../../component/commonVariable';

export default function NewList() {
  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/books/book/getList/신간/1`).then(result => {
      // console.log(result.data);
      setBookList(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, []);

  return (
    <BodyContainer>
      <BodyTitle>신간 도서</BodyTitle>
      <Box sx={{ backgroundColor: '#DADADA', height: '1px', mt: '12px', mb: '20px' }} />
      <BookList bookList={bookList} category="신간" />
    </BodyContainer>
  );
}
