import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  url,
  youtubeItemNumber,
  bookItemNumber,
  lectureItemNumber,
} from '../component/commonVariable';

// ㅇ 메인영역
/** (공통) 메인 배너 훅 */
export function bannerMainHook(company, category, device) {
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/${company}/main/banner/getList/${category}/${device}`).then(result => {
      // console.log(result.data);
      setBannerList(result.data);
    });
  }, []);

  return bannerList;
}

/** (아카데미) 메인 강의 훅 */
export function lectureMainHook(type) {
  const [lectureList, setLectureList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/academy/main/lecture/getList/${type}`).then(result => {
      // console.log(result.data);
      setLectureList(result.data);
    });
  }, []);

  return lectureList;
}

/** (공통) 유튜브 메인 훅 */
export function youtubeMainHook(company, category) {
  const [youtubeList, setYoutubeList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/${company}/main/youtube/getList/${category}`).then(result => {
      // console.log(result.data);
      setYoutubeList(result.data);
    });
  }, []);

  return youtubeList;
}

/** (북스) 북 메인 훅 */
export function bookMainHook(category) {
  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    axios.get(`${url}/books/main/book/getList/${category}`).then(result => {
      // console.log(result.data);
      setBookList(result.data);
    });
  }, []);

  return bookList;
}

// ㅇ 메인 외
/** (공통) 유튜브 api 훅 */
export function youtubeHook(company, category, searchInput, refreshSwitch) {
  const [youtubeList, setYoutubeList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/${company}/youtube/getList/${category}/${page}`).then(result => {
        // console.log(result.data);
        setYoutubeList(result.data);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
    } else {
      axios
        .get(`${url}/${company}/youtube/search/getList/${category}/${searchInput}/${page}`)
        .then(result => {
          // console.log(result.data);
          setYoutubeList(result.data);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    }
  }, [page, refreshSwitch]);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/${company}/youtube/getTotalNum/${category}`).then(result => {
        // console.log(result.data);
        setTotalItem(result.data.totalNum);
      });
    } else {
      axios
        .get(`${url}/${company}/youtube/search/getTotalNum/${category}/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setTotalItem(result.data.totalNum);
        });
    }
  }, [refreshSwitch]);

  return [youtubeList, page, setPage, totalItem, youtubeItemNumber];
}

/** (북스) 서적 api 훅 */
export function bookHook(category, searchInput, refreshSwitch) {
  const [bookList, setBookList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/books/book/getList/${category}/${page}`).then(result => {
        // console.log(result.data);
        setBookList(result.data);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
    } else {
      axios
        .get(`${url}/books/book/search/getList/${category}/${searchInput}/${page}`)
        .then(result => {
          // console.log(result.data);
          setBookList(result.data);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    }
  }, [page, refreshSwitch]);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/books/book/getTotalNum/${category}`).then(result => {
        // console.log(result.data);
        setTotalItem(result.data.totalNum);
      });
    } else {
      axios.get(`${url}/books/book/search/getTotalNum/${category}/${searchInput}`).then(result => {
        // console.log(result.data);
        setTotalItem(result.data.totalNum);
      });
    }
  }, [refreshSwitch]);

  return [bookList, page, setPage, totalItem, bookItemNumber];
}

/** (아카데미) 강의 api 훅 */
export function lectureHook(category, searchInput, refreshSwitch) {
  const [lectureList, setLectureList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/academy/lecture/getList/${category}/${page}`).then(result => {
        // console.log(result.data);
        setLectureList(result.data);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
    } else {
      axios
        .get(`${url}/academy/lecture/search/getList/${category}/${searchInput}/${page}`)
        .then(result => {
          // console.log(result.data);
          setLectureList(result.data);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    }
  }, [page, refreshSwitch]);

  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/academy/lecture/getTotalNum/${category}`).then(result => {
        // console.log(result.data);
        setTotalItem(result.data.totalNum);
      });
    } else {
      axios
        .get(`${url}/academy/lecture/search/getTotalNum/${category}/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setTotalItem(result.data.totalNum);
        });
    }
  }, [refreshSwitch]);

  return [lectureList, page, setPage, totalItem, lectureItemNumber];
}
