import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Stack, Box, Button, Typography, Checkbox, CardMedia } from '@mui/material';
import imageCompression from 'browser-image-compression';
import QuillEditor from '../../../component/UI/Admin/QuillEditor';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import {
  BodyTitle,
  NormalContentsSubTitle,
  NormalContentsTitle,
} from '../../../component/UI/TextStyle';
import { url } from '../../../component/commonVariable';
import ConfirmModal from '../../../component/UI/ConfirmModal';

export default function EditBook() {
  const navigate = useNavigate();
  const { category, bookId } = useParams();
  const mode = bookId === 'add' ? 'add' : 'edit';

  // 파일 추가
  const [newImage, setNewImage] = useState({ name: '' });
  const fileInput = useRef();

  const checkboxField = [
    { label: '공개 유무', keyName: 'isPublished' },
    { label: '신간 유무', keyName: 'isNew' },
  ];

  const editField = [
    { label: '노출 순서', keyName: 'bookOrder', width: 0.3, addText: '높은 숫자 먼저 노출' },
    { label: '제목', keyName: 'title', width: 0.7 },
    { label: '부제목', keyName: 'subTitle', width: 0.7 },
    { label: '소개', keyName: 'intro', width: 0.7 },
    { label: '저자', keyName: 'author', width: 0.7 },
    { label: '가격', keyName: 'price', width: 0.3 },
    { label: '분야', keyName: 'genre', width: 0.3 },
    { label: '출간일', keyName: 'publishedAt', width: 0.3, addText: 'ex) 20221225' },
    { label: '쪽수', keyName: 'page', width: 0.3 },
    { label: 'ISBN', keyName: 'isbn', width: 0.3 },
    // eslint-disable-next-line
    { label: '종이책', keyName: 'paperBookLink', width: 0.7, addText: '* 전체 링크를 입력해주세요' },
    { label: '전자책', keyName: 'eBookLink', width: 0.7, addText: '* 전체 링크를 입력해주세요' },
  ];
  const [editInput, setEditInput] = useState({ isPublished: false, isNew: false });

  const [editQuillIntro, setEditQuillIntro] = useState('');
  const [editQuillIndex, setEditQuillIndex] = useState('');
  const [editQuillAuthor, setEditQuillAuthor] = useState('');

  const editorField = [
    { label: '책 소개', data: editQuillIntro, setData: setEditQuillIntro },
    { label: '목차', data: editQuillIndex, setData: setEditQuillIndex },
    { label: '저자 소개', data: editQuillAuthor, setData: setEditQuillAuthor },
  ];

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  useEffect(() => {
    if (bookId !== 'add') {
      axios.get(`${url}/admin/books/book/getDetail/text/${bookId}`).then(result => {
        // console.log(result.data[0]);

        const tempObj = { ...result.data[0] };
        // eslint-disable-next-line
        tempObj.isPublished = tempObj.isPublished === 1 ? true : false;
        // eslint-disable-next-line
        tempObj.isNew = tempObj.isNew === 1 ? true : false;
        tempObj.bookOrder = tempObj.bookOrder.toString();
        setEditInput(tempObj);
      });
    }
  }, []);

  useEffect(() => {
    if (bookId !== 'add') {
      axios.get(`${url}/admin/books/book/getDetail/editor/${bookId}`).then(result => {
        // 데이터 받아서 각 editor에 쏴주는
        editorField.forEach(function (each) {
          each.setData(result.data.filter(e => e.category === each.label)[0].contents);
        });
      });
    }
  }, []);

  const addFile = () => {
    fileInput.current.click();
  };

  // 이미지 압축 onChange 대신 먼저
  async function handleImageUpload(event) {
    // gif는 그냥 올리게. 압축 시 png로 되는
    if (event.target.files[0].type === 'image/gif') {
      onChangeCompressed(event.target.files[0]);
    } else {
      const imageFile = event.target.files[0];
      // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
      // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        await onChangeCompressed(compressedFile); // write your own logic
      } catch (error) {
        console.log(error);
      }
    }
  }

  // 올라간 이미지
  const onChangeCompressed = image => {
    setNewImage(image);
    encodeFileToBase64(image);
  };

  // 보이게 하는 로직
  const encodeFileToBase64 = fileBlob => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);

    return new Promise(resolve => {
      reader.onload = () => {
        const tempObj = { ...editInput };
        tempObj.img = reader.result;
        setEditInput(tempObj);
        resolve();
      };
    });
  };

  // editData
  const onChangeEditInput = e => {
    const { name, value } = e.target;
    if (name === 'isPublished' || name === 'isNew') {
      setEditInput({
        ...editInput,
        [name]: editInput[name] ? !editInput[name] : true,
      });
    } else {
      setEditInput({
        ...editInput,
        [name]: value,
      });
    }
  };

  const onSubmit = () => {
    const body = { category, ...editInput, editQuillIntro, editQuillIndex, editQuillAuthor };

    if (editInput.isPublished === '') {
      alert('출간일은 필수입니다 ex)20221225');
    } else if (Object.keys(editInput).length < 9) {
      alert('입력하지 않은 값이 너무 많습니다');
    } else {
      axios.post(`${url}/admin/books/book/${mode}/contents`, body).then(result => {
        if (newImage.name === '') {
          console.log(result.data);
          alert('텍스트 등록이 완료되었습니다.');
          navigate('/Admin/Books');
        } else {
          const formData = new FormData();
          formData.append('fileName', newImage.name);
          formData.append('bookId', result.data.bookId);
          formData.append('directory', 'BookCover');
          formData.append('file', newImage); // formData는 키-밸류 구조

          axios({
            headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8',
            },
            url: `${url}/admin/books/book/${mode}/image`,
            method: 'POST',
            data: formData,
          }).then(() => {
            alert('텍스트 & 이미지 저장이 완료되었습니다');
            navigate('/Admin/Books');
          });
        }
      });
    }
  };

  const openDeleteModal = () => {
    setDeleteConfirmModal(true);
  };

  const deleteBook = () => {
    axios.post(`${url}/admin/books/book/delete/${bookId}`).then(() => {
      alert('삭제가 완료되었습니다.');
      navigate('/Admin/Books');
    });
  };

  const goBack = () => {
    navigate('/Admin/Books');
  };

  return (
    <div>
      <BodyContainer>
        <BodyTitle sx={{ mb: '20px' }}>책 등록/수정</BodyTitle>

        <input type="file" hidden onChange={handleImageUpload} ref={fileInput} accept="image/*" />
        <CardMedia
          component="img"
          image={editInput.img}
          onClick={addFile}
          sx={{
            width: { xs: '180px', lg: '240px' },
            height: { xs: '265px', lg: '352px' },
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer',
            ':hover': {
              opacity: 0.7,
            },
          }}
        />
        <NormalContentsTitle sx={{ mt: '5px' }}>(이미지 미리보기)</NormalContentsTitle>

        <Stack direction="row" alignItems="center" spacing="20px" sx={{ mt: '30px' }}>
          {checkboxField.map(function (each) {
            return (
              <Stack key={each.label} direction="row" alignItems="center">
                <NormalContentsTitle>{each.label}</NormalContentsTitle>
                <Checkbox
                  checked={editInput[each.keyName] || false}
                  name={each.keyName}
                  onChange={onChangeEditInput}
                />
              </Stack>
            );
          })}
        </Stack>

        <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', mt: '15px', mb: '20px' }} />

        {editField.map(function (each) {
          return (
            <Stack
              key={each.label}
              direction="row"
              spacing={3}
              alignItems="center"
              justifyItems="flex-start"
              sx={{ mb: '15px' }}
            >
              <Box sx={{ width: '70px' }}>
                <Typography align="left" fontSize={18} fontWeight={600}>
                  {each.label}
                </Typography>
              </Box>
              <TextField
                name={each.keyName}
                value={editInput[each.keyName] || ''}
                onChange={onChangeEditInput}
                sx={{ width: each.width }}
                type={
                  each.keyName === 'publishedAt' ||
                  each.keyName === 'price' ||
                  each.keyName === 'page'
                    ? 'number'
                    : null
                }
              />
              {each.addText && <NormalContentsSubTitle>{each.addText}</NormalContentsSubTitle>}
            </Stack>
          );
        })}

        <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', mt: '30px', mb: '20px' }} />

        {editorField.map(function (each) {
          return (
            <div key={each.label}>
              <NormalContentsTitle sx={{ my: '10px' }}>{each.label}</NormalContentsTitle>
              <QuillEditor
                editQuillContents={each.data}
                setEditQuillContents={each.setData}
                directory="Writing"
              />
            </div>
          );
        })}

        <Button onClick={onSubmit} sx={{ mt: '30px', width: '100px' }}>
          작성완료
        </Button>
        {bookId !== 'add' && (
          <Button
            onClick={openDeleteModal}
            color="secondary"
            sx={{ ml: '10px', mt: '30px', width: '100px' }}
          >
            삭제
          </Button>
        )}

        <Button color="grey" onClick={goBack} sx={{ mt: '30px', width: '100px', ml: '10px' }}>
          뒤로가기
        </Button>

        {deleteConfirmModal && (
          <ConfirmModal
            modalSwitch={deleteConfirmModal}
            setModalSwitch={setDeleteConfirmModal}
            title="삭제 확인"
            contents="정말 해당 게시물을 삭제하시겠습니까?"
            func={deleteBook}
          />
        )}
      </BodyContainer>
    </div>
  );
}
