import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import NoticeSection from '../../../component/UI/NoticeSection';

export default function TheLineNotice() {
  return (
    <div>
      <FirstSecionSimple
        titleText="공지사항"
        backImg="integrated/notice_community_background.png"
      />
      <GrayPositionBar positionText1="공지사항" />
      <NoticeSection />
    </div>
  );
}
