import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import YoutubeSection from '../../../component/UI/YoutubeSection';

export default function BookTrailer() {
  return (
    <div>
      <FirstSecionSimple
        titleText="더라인북스 북트레일러"
        subNav
        backImg="Books/booktrailer_background.png"
      />
      <YoutubeSection company="books" category="북트레일러" />
    </div>
  );
}
