import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Modal, Button, Box, ButtonGroup, Typography } from '@mui/material';
import { url } from '../../commonVariable';

export default function EditLectureCategoryModal({
  modalSwitch,
  setModalSwitch,
  editData,
  refreshSwitch,
  setRefreshSwitch,
}) {
  const { lectureId } = editData;
  const [editCategory, setEditCategory] = useState(editData.category);

  const modalClose = () => {
    setModalSwitch(false);
  };

  const saveData = () => {
    const body = { lectureId, category: editCategory };
    axios.post(`${url}/admin/academy/lecture/edit/category`, body).then(() => {
      setRefreshSwitch(!refreshSwitch);
      alert('정상적으로 완료되었습니다');
      modalClose();
    });
  };

  const changeCategory = cate => {
    setEditCategory(cate);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 400,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 40px 45px 40px',
            outline: 'none',
          }}
        >
          <Typography align="center" fontSize={24} fontWeight={600}>
            강의 변경하기
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '20px' }}>
            <ButtonGroup sx={{ mb: '20px' }}>
              <Button
                onClick={() => changeCategory('온라인 강의')}
                variant={editCategory === '온라인 강의' ? 'contained' : 'outlined'}
              >
                온라인 강의
              </Button>
              <Button
                onClick={() => changeCategory('오프라인 강의')}
                variant={editCategory === '오프라인 강의' ? 'contained' : 'outlined'}
              >
                오프라인 강의
              </Button>
              <Button
                onClick={() => changeCategory('특강')}
                variant={editCategory === '특강' ? 'contained' : 'outlined'}
              >
                특강
              </Button>
            </ButtonGroup>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={saveData}
              color="secondary"
              sx={{ color: 'white', mr: '30px', width: '100px' }}
            >
              수정하기
            </Button>

            <Button variant="contained" color="grey" onClick={modalClose} sx={{ width: '100px' }}>
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

EditLectureCategoryModal.defaultProps = {
  editData: {},
  modalSwitch: true,
  setModalSwitch: () => {},
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

EditLectureCategoryModal.propTypes = {
  // eslint-disable-next-line
  editData: PropTypes.object,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
