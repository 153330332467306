import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BodyContainer } from './ContainerStyle';
import { BodyTitleWithSearch } from './BodyTitleSection';
import LectureList from './LectureList';
import Pagination from '../Pagination';
import { lectureHook } from '../../module/useEffectHooks';

export default function LectureSection({ category }) {
  const [searchInput, setSearchInput] = useState('');
  const [refreshSwitch, setRefreshSwtich] = useState(true);

  const [lectureList, page, setPage, totalItem, lectureItemNumber] = lectureHook(
    category,
    searchInput,
    refreshSwitch,
  );

  return (
    <BodyContainer>
      <BodyTitleWithSearch
        mainTitle={category}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        refreshSwitch={refreshSwitch}
        setRefreshSwtich={setRefreshSwtich}
      />
      <LectureList lectureList={lectureList} />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={lectureItemNumber} />
    </BodyContainer>
  );
}

LectureSection.defaultProps = {
  category: '카테고리를 입력해주세요',
};

LectureSection.propTypes = {
  category: PropTypes.string,
};
