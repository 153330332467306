import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/MainBanner.css';
import { bannerMainHook } from '../../module/useEffectHooks';
import { NormalContentsTitle } from './TextStyle';
import { bannerRatioMob, bannerRatioPC } from '../commonVariable';
import ArrowRightWhiteIcon from '../../assets/arrowRightWhite.png';
import { url } from '../commonVariable';

export default function MainBanner({ company, category, device, isMobile }) {
  const bannerList = bannerMainHook(company, category, device);

  const nextArr = <Arrow type="next" isMobile={isMobile} />;
  const prevArr = <Arrow type="prev" isMobile={isMobile} />;

  // 드래그 방지를 위한
  let dragging = false;
  const sliderSettings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    // eslint-disable-next-line
    beforeChange: () => dragging = true,
    // eslint-disable-next-line
    afterChange: () => dragging = false,
    nextArrow: nextArr,
    prevArrow: prevArr,
  };

  const goToLink = (link, id) => {
    if (!dragging) {
      window.open(link);
      axios.post(`${url}/common/bannerCount/${id}`);
    }
  };

  return (
    <Box>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Slider {...sliderSettings}>
        {bannerList.map(function (each) {
          return (
            <Box
              key={each.bannerId}
              onClick={() => {
                goToLink(each.link, each.bannerId);
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              <CardMedia
                alt={`mainBanner_${each.bannerId}`}
                image={each.img}
                sx={{
                  aspectRatio: { xs: bannerRatioMob, lg: bannerRatioPC },
                  width: { xs: '100vw', lg: '100vw' },
                  height: { lg: '372px', xl: '' },
                }}
              />
              <NormalContentsTitle sx={{ mt: '13px' }}>{each.title}</NormalContentsTitle>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

MainBanner.defaultProps = {
  company: '',
  category: '',
  device: '',
  isMobile: false,
};

MainBanner.propTypes = {
  company: PropTypes.string,
  category: PropTypes.string,
  device: PropTypes.string,
  isMobile: PropTypes.bool,
};

// eslint-disable-next-line
function Arrow({ type, isMobile, onClick }) {

  let arrowClassName = type === 'next' ? 'mainNextArrow' : 'mainPrevArrow';

  const char =
    type === 'next' ? (
      <CardMedia
        component="img"
        image={ArrowRightWhiteIcon}
        alt="home"
        sx={{ width: { xs: '8px', lg: '16px' }, height: { xs: '12px', lg: '24px' } }}
      />
    ) : (
      <CardMedia
        component="img"
        image={ArrowRightWhiteIcon}
        alt="home"
        sx={{
          width: { xs: '8px', lg: '16px' },
          height: { xs: '12px', lg: '24px' },
          transform: 'rotate(180deg)',
        }}
      />
    );

  if (isMobile) {
    arrowClassName = type === 'next' ? 'mobileMainNextArrow' : 'mobileMainPrevArrow';
  }

  arrowClassName += ' arrow';

  return (
    // eslint-disable-next-line
    <span className={arrowClassName} onClick={onClick}>
      {char}
    </span>
  );
}

Arrow.defaultProps = {
  type: '',
  isMobile: false,
};

Arrow.propTypes = {
  type: PropTypes.string,
  isMobile: PropTypes.bool,
};
