import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Box, CardMedia } from '@mui/material';
import { bookMainHook } from '../../../module/useEffectHooks';
import { BodyTitleWithSub } from '../../../component/UI/BodyTitleSection';
import { NormalContentsTitle } from '../../../component/UI/TextStyle';

export default function BookParts({ category, allLink }) {
  const bookList = bookMainHook(category);

  const navigate = useNavigate();

  const goToDetail = id => {
    navigate(`/Books/book/${category}/${id}`);
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const goToAll = () => {
    navigate(allLink);
  };

  return (
    <Box sx={{ mb: '90px' }}>
      <BodyTitleWithSub mainTitle={category} subTitle="전체보기" onClickSub={goToAll} />
      <Box sx={{ height: '1px', backgroundColor: '#DADADA', mb: '20px', mt: '12px' }} />

      <Grid container spacing={{ xs: '20px', lg: '30px' }}>
        {bookList.map(function (each, index) {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              lg={index !== 5 ? 2.4 : 0.1}
              key={each.bookId}
              sx={{ mb: { lg: '-40px' } }}
            >
              <CardMedia
                component="img"
                alt={`book CoverImage-${each.title}`}
                image={each.img || ''}
                onClick={() => goToDetail(each.bookId)}
                sx={{
                  width: 1,
                  aspectRatio: '240/351',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
              <NormalContentsTitle sx={{ mt: '13px' }}>{each.title}</NormalContentsTitle>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

BookParts.defaultProps = {
  category: '타입을 입력해주세요',
  allLink: '',
};

BookParts.propTypes = {
  category: PropTypes.string,
  allLink: PropTypes.string,
};
