import React, { useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { AdminFirstSecion } from '../../../component/UI/FirstSection';
import AdminYoutubeSection from '../../../component/UI/Admin/AdminYoutubeSection';
import AdminBookSection from './AdminBookSection';
import AdminBannerSection from '../../../component/UI/Admin/AdminBannerSection';
import LoginCheck from '../../../module/LoginCheck';

export default function AdminBooks() {
  LoginCheck();

  const [selectedMenu, setSelectedMenu] = useState('');
  const menuList = ['배너 관리', '신간', '단행본', '전자책', '북트레일러', '저자 인터뷰'];
  const componentList = [
    <AdminBannerSection key="북스 배너" category="북스 배너" />,
    <AdminBookSection key="신간" category="신간" />,
    <AdminBookSection key="단행본" category="단행본" />,
    <AdminBookSection key="전자책" category="전자책" />,
    <AdminYoutubeSection key="북트레일러" category="북트레일러" />,
    <AdminYoutubeSection key="저자 인터뷰" category="저자 인터뷰" />,
  ];

  const selectMenu = menu => {
    if (selectedMenu === menu) {
      setSelectedMenu('');
    } else {
      setSelectedMenu(menu);
    }
  };

  return (
    <div>
      <AdminFirstSecion titleText="더라인북스 관리자" />
      <ButtonGroup variant="outlined" color="secondary" sx={{ my: '30px' }}>
        {menuList.map(function (eachMenu) {
          return (
            <Button
              variant={selectedMenu === eachMenu ? 'contained' : 'outlined'}
              onClick={() => selectMenu(eachMenu)}
              key={eachMenu}
            >
              {eachMenu}
            </Button>
          );
        })}
      </ButtonGroup>
      {menuList.map(function (eachMenu, index) {
        return (
          <div key={`${eachMenu} ComponenetDiv`}>
            {selectedMenu === eachMenu && componentList[index]}
          </div>
        );
      })}
    </div>
  );
}
