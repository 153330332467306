import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Stack, Box, Button, Checkbox } from '@mui/material';
import QuillEditor from '../../../component/UI/Admin/QuillEditor';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import {
  BodyTitle,
  NormalContentsSubTitle,
  NormalContentsTitle,
} from '../../../component/UI/TextStyle';
import { CategoryBadge } from '../../../component/UI/Badge';
import { url } from '../../../component/commonVariable';
import ConfirmModal from '../../../component/UI/ConfirmModal';

export default function EditLectureNotice() {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const mode = noticeId === 'add' ? 'add' : 'edit';

  const whichCompany = window.location.pathname.split('/')[2].toLowerCase();

  // contents는 editQuillContents로 따로 관리
  const defaultInput = {
    category: '',
    title: '',
    isImportant: false,
  };

  const [editInput, setEditInput] = useState(defaultInput);
  const [editQuillContents, setEditQuillContents] = useState('');

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  useEffect(() => {
    if (noticeId !== 'add') {
      axios.get(`${url}/admin/${whichCompany}/Notice/getDetail/${noticeId}`).then(result => {
        const tempObj = { ...result.data[0] };
        // 1,0을 T/F로 변환 + contents 분리하여 setState 적용
        // eslint-disable-next-line
        tempObj.isImportant = tempObj.isImportant === 1 ? true : false;
        delete tempObj.contents;

        setEditQuillContents(result.data[0].contents);
        setEditInput(tempObj);
      });
    }
  }, []);

  const onChangeEditInput = e => {
    const { name, value } = e.target;
    if (name === 'isImportant') {
      setEditInput({
        ...editInput,
        [name]: !editInput.isImportant,
      });
    } else {
      setEditInput({
        ...editInput,
        [name]: value,
      });
    }
  };

  const onSubmit = () => {
    if (!editInput.category) {
      alert('카테고리를 입력해주세요.\nex) 공지');
    } else if (!editInput.title) {
      alert('제목을 입력해주세요.');
    } else if (!editQuillContents) {
      alert('본문 내용이 없습니다!');
    } else {
      const body = { ...editInput, editQuillContents };
      axios.post(`${url}/admin/academy/Notice/${mode}`, body).then(() => {
        alert('저장이 완료되었습니다');
        navigate('/Admin/Academy');
      });
    }
  };

  const openDeleteModal = () => {
    setDeleteConfirmModal(true);
  };

  const deleteNotice = () => {
    axios.post(`${url}/admin/academy/Notice/delete/${noticeId}`).then(() => {
      alert('삭제가 완료되었습니다.');
      navigate('/Admin/Academy');
    });
  };

  return (
    <div>
      <BodyContainer>
        <BodyTitle sx={{ mb: '20px' }}>강의소식</BodyTitle>

        <Stack direction="row" alignItems="center" spacing="10px">
          <Box sx={{ width: '70px' }}>
            <NormalContentsTitle>카테고리</NormalContentsTitle>
          </Box>
          <TextField
            placeholder="카테고리"
            value={editInput.category}
            name="category"
            onChange={onChangeEditInput}
            sx={{ width: '80px' }}
          />
          <NormalContentsSubTitle>ex) 안내, 공지 등</NormalContentsSubTitle>
          {editInput.category && <CategoryBadge badgeText={editInput.category} />}
        </Stack>

        <Stack direction="row" alignItems="center" spacing="10px" sx={{ my: '10px' }}>
          <Box sx={{ width: '70px' }}>
            <NormalContentsTitle>제목</NormalContentsTitle>
          </Box>
          <TextField
            placeholder="제목을 입력해주세요"
            value={editInput.title}
            name="title"
            onChange={onChangeEditInput}
            sx={{ width: '500px' }}
          />
          <Checkbox
            checked={editInput.isImportant}
            onChange={onChangeEditInput}
            name="isImportant"
          />
          고정 게시물
        </Stack>

        <QuillEditor
          editQuillContents={editQuillContents}
          setEditQuillContents={setEditQuillContents}
          directory="Writing"
        />

        <Button onClick={onSubmit} sx={{ mt: '30px', width: '100px' }}>
          작성완료
        </Button>
        {noticeId !== 'add' && (
          <Button
            onClick={openDeleteModal}
            color="secondary"
            sx={{ ml: '10px', mt: '30px', width: '100px' }}
          >
            삭제
          </Button>
        )}

        {deleteConfirmModal && (
          <ConfirmModal
            modalSwitch={deleteConfirmModal}
            setModalSwitch={setDeleteConfirmModal}
            title="삭제 확인"
            contents="정말 해당 게시물을 삭제하시겠습니까?"
            func={deleteNotice}
          />
        )}
      </BodyContainer>
    </div>
  );
}
