import React from 'react';
import BookSection from '../../../component/UI/BookSection';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';

export default function Monograph() {
  return (
    <div>
      <FirstSecionSimple titleText="더라인북스 단행본" subNav backImg="Books/monograph_back.png" />
      <BookSection category="단행본" />
    </div>
  );
}
