import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { TheLineBodyContainer } from '../ContainerStyle';
import { BodyTitle, NormalContentsTitle } from '../TextStyle';
import { url } from '../../commonVariable';
import AdminBannerList from './AdminBannerList';
import AddEditBannerModal from './AddEditBannerModal';

export default function AdminBannerSection({ category }) {
  // books || acadmy
  const whichCompany = window.location.pathname.split('/')[2].toLowerCase();

  const [bannerListPC, setBannerListPC] = useState([]);
  const [bannerListMob, setBannerListMob] = useState([]);

  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/banner/getList/PC/${category}`).then(result => {
      // console.log(result.data);
      setBannerListPC(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [refreshSwitch]);

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/banner/getList/Mob/${category}`).then(result => {
      // console.log(result.data);
      setBannerListMob(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [refreshSwitch]);

  const openAddModal = () => {
    setEditData({});
    setAddEditModalSwitch(true);
  };

  const openEditModal = data => {
    setEditData(data);
    setAddEditModalSwitch(true);
  };

  return (
    <TheLineBodyContainer>
      <Stack direction="row" spacing="30px" sx={{ mb: '15px' }}>
        <BodyTitle>{category}</BodyTitle>
        <Button onClick={openAddModal} sx={{ fontSize: '15px' }}>
          새 콘텐츠 등록
        </Button>
      </Stack>

      <NormalContentsTitle sx={{ mb: '10px' }}>PC 배너</NormalContentsTitle>
      <AdminBannerList bannerList={bannerListPC} adminFunc={openEditModal} isPC />

      <NormalContentsTitle sx={{ mb: '10px', mt: '50px' }}>Mob 배너</NormalContentsTitle>
      <AdminBannerList bannerList={bannerListMob} adminFunc={openEditModal} isPC={false} />

      {addEditModalSwitch && (
        <AddEditBannerModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
          category={category}
          whichCompany={whichCompany}
          editData={editData}
        />
      )}
    </TheLineBodyContainer>
  );
}

AdminBannerSection.defaultProps = {
  category: '',
};

AdminBannerSection.propTypes = {
  // eslint-disable-next-line
  category: PropTypes.string,
};
