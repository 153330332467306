import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import { TheLineBodyContainer } from './ContainerStyle';
import { BodyTitle } from './TextStyle';
import { BodyTitleWithSub } from './BodyTitleSection';
import { CategoryBadge } from './Badge';
import { url, noticeItemNumber } from '../commonVariable';
import { changeDateDash } from '../commonFunction';
import Pagination from '../Pagination';

export default function NoticeSection({ isMain }) {
  const navigate = useNavigate();

  const pathName = window.location.pathname;
  const whichRoute = pathName === '/Notice' || pathName === '/' ? 'theline' : 'academy';

  const [noticeList, setNoticeList] = useState([]);
  const [page, setPage] = useState(1);
  const [noticeItemObj, setNoticeItemObj] = useState({ totalNum: 14, important: 2, normal: 12 });

  const [searchInput, setSearchInput] = useState('');
  const [refreshSwitch, setRefreshSwitch] = useState(false);

  // 데이터 목록
  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/${whichRoute}/Notice/getList/${page}`).then(result => {
        // console.log(result.data);
        setNoticeList(result.data);
      });
    } else {
      axios
        .get(`${url}/${whichRoute}/Notice/search/getList/${searchInput}/${page}`)
        .then(result => {
          // console.log(result.data);
          setNoticeList(result.data);
        });
    }

    if (pathName === '/Notice') {
      window.scrollTo({ top: 300, left: 0, behavior: 'smooth' });
    }
  }, [page, refreshSwitch]);

  // 개수
  useEffect(() => {
    if (searchInput === '') {
      axios.get(`${url}/${whichRoute}/Notice/getTotalNum`).then(result => {
        // console.log(result.data);
        setNoticeItemObj(result.data);
      });
    } else {
      axios.get(`${url}/${whichRoute}/Notice/search/getTotalNum/${searchInput}`).then(result => {
        // console.log(result.data);
        setNoticeItemObj(result.data);
      });
    }
  }, [refreshSwitch]);

  const goToNoti = () => {
    navigate('/Notice');
  };

  const goToDetail = id => {
    if (pathName === '/Notice' || pathName === '/') {
      navigate(`/Notice/${id}`);
    } else if (pathName === '/Academy/LectureNews') {
      navigate(`/Academy/Notice/${id}`);
    }
  };

  const onChangeSearch = e => {
    setSearchInput(e.target.value);
  };

  const searchData = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  // 엔터 검색
  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      searchData();
    }
  };

  return (
    <TheLineBodyContainer>
      {isMain ? (
        <BodyTitleWithSub mainTitle="공지사항" subTitle="전체보기" onClickSub={goToNoti} />
      ) : (
        <BodyTitle>{pathName === '/Notice' ? '공지사항' : '강의소식'}</BodyTitle>
      )}
      <Table sx={{ mt: { xs: '18px', lg: '15px', xl: '21px' } }}>
        <TableBody
          sx={{
            borderTop: { xs: '0.5px solid #222222', xl: '1px solid #222222' },
            borderBottom: { xs: '0.5px solid #222222', xl: '1px solid #222222' },
          }}
        >
          {noticeList.map(function (each) {
            return (
              <TableRow
                onClick={() => goToDetail(each.noticeId)}
                key={each.noticeId}
                sx={[
                  each.isImportant === 1 ? { backgroundColor: '#F5FBFF' } : null,
                  {
                    '&:last-child td': {
                      borderBottom: 0,
                    },
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    },
                  },
                ]}
              >
                {/* <TableCell
                  sx={{
                    py: '0px',
                    px: '0px',
                    width: { xs: '36px', lg: '65px', xl: '65px' },
                    height: { lg: '90px', xl: '105px' },
                    fontSize: { xs: '12px', lg: '15px', xl: '16px' },
                    fontWeight: 700,
                  }}
                >
                  {each.noticeId}
                </TableCell> */}
                {pathName === '/Academy/LectureNews' && (
                  <TableCell
                    align="center"
                    sx={{
                      width: { xs: '20px', lg: '70px', xl: '70px' },
                    }}
                  >
                    <CategoryBadge badgeText={each.category} />
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  sx={[
                    pathName === '/Academy/LectureNews'
                      ? { pl: '0px' }
                      : { pl: { xs: '20px', lg: '30px' } },
                    {
                      fontSize: { xs: '12px', lg: '18px', xl: '20px' },
                      fontWeight: 500,
                    },
                  ]}
                >
                  {each.title}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: '#666666',
                    minWidth: { xs: '70px' },
                    fontSize: { xs: '11px', lg: '18px', xl: '20px' },
                  }}
                >
                  {changeDateDash(each.createdAt)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!isMain && (
        <Box sx={{ mt: '40px' }}>
          <Stack direction="row" justifyContent="center" alignContent="center" spacing="10px">
            <TextField
              placeholder="제목 검색"
              value={searchInput}
              onChange={onChangeSearch}
              onKeyPress={onCheckEnter}
            />
            <Button onClick={searchData} sx={{ fontSize: { xs: '15px', lg: '16px' } }}>
              검색
            </Button>
          </Stack>
          <Box sx={{ mt: '-20px' }}>
            <Pagination
              page={page}
              setPage={setPage}
              totalItem={noticeItemObj.normal}
              itemNum={noticeItemNumber - noticeItemObj.important}
            />
          </Box>
        </Box>
      )}
    </TheLineBodyContainer>
  );
}

NoticeSection.defaultProps = {
  isMain: false,
};

NoticeSection.propTypes = {
  isMain: PropTypes.bool,
};
