import React from 'react';
import { Box } from '@mui/material';
import SubNavBar from '../../../component/NavBar/SubNavBar';
import BookIntroPC from './BookIntroPC';
import BookIntroMob from './BookIntroMob';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import BookDescription from './BookDescription';

export default function BooksDetail() {
  return (
    <div>
      <SubNavBar />

      <BodyContainer>
        {/* Mob Intro */}
        <Box sx={{ display: { lg: 'none' } }}>
          <BookIntroMob />
        </Box>

        {/* PC Intro */}
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <BookIntroPC />
        </Box>

        <BookDescription />
      </BodyContainer>
    </div>
  );
}
