import React, { useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { AdminFirstSecion } from '../../../component/UI/FirstSection';
import AdminYoutubeSection from '../../../component/UI/Admin/AdminYoutubeSection';
import AdminLectureSection from './AdminLectureSection';
import AdminNoticeSection from '../../../component/UI/Admin/AdminNoticeSection';
import AdminBannerSection from '../../../component/UI/Admin/AdminBannerSection';
import LoginCheck from '../../../module/LoginCheck';

export default function AdminAcademy() {
  LoginCheck();

  const [selectedMenu, setSelectedMenu] = useState('');
  const menuList = [
    '배너 관리',
    '강의 소식',
    '온라인 강의',
    '오프라인 강의',
    '특강',
    '동영상 강의',
    'TLS 영상',
  ];
  const componentList = [
    <AdminBannerSection key="아카데미 배너" category="아카데미 배너" />,
    <AdminNoticeSection key="강의 소식" />,
    <AdminLectureSection key="온라인 강의" category="온라인 강의" />,
    <AdminLectureSection key="오프라인 강의" category="오프라인 강의" />,
    <AdminLectureSection key="특강" category="특강" />,
    <AdminYoutubeSection key="동영상 강의" category="동영상 강의" />,
    <AdminYoutubeSection key="TLS 사용법 예시" category="TLS 사용법 예시" />,
  ];

  const selectMenu = menu => {
    if (menu === selectedMenu) {
      setSelectedMenu('');
    } else {
      setSelectedMenu(menu);
    }
  };

  return (
    <div>
      <AdminFirstSecion titleText="더라인아카데미 관리자" />
      <ButtonGroup variant="outlined" color="secondary" sx={{ my: '30px' }}>
        {menuList.map(function (eachMenu) {
          return (
            <Button
              variant={selectedMenu === eachMenu ? 'contained' : 'outlined'}
              onClick={() => selectMenu(eachMenu)}
              key={eachMenu}
            >
              {eachMenu}
            </Button>
          );
        })}
      </ButtonGroup>
      {menuList.map(function (eachMenu, index) {
        return (
          <div key={`${eachMenu} ComponenetDiv`}>
            {selectedMenu === eachMenu && componentList[index]}
          </div>
        );
      })}
    </div>
  );
}
