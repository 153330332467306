import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardMedia, Grid, Stack, Box, Typography, Button } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from '../../../component/UI/TextStyle';
import { changeDateDash } from '../../../component/commonFunction';

/** 서적 리스트 UI */
export default function AdminBookList({ bookList, category }) {
  const navigate = useNavigate();

  const goToDetail = id => {
    window.open(`/Books/Book/${category}/${id}`);
  };

  const goToEdit = id => {
    navigate(`/Admin/Books/EditBook/${category}/${id}`);
  };

  return (
    <Grid container spacing={{ lg: '50px' }}>
      {bookList.map(function (each) {
        return (
          <Grid item xs={6} key={each.bookId}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              spacing={{ lg: '21px', xl: '24px' }}
              onClick={() => goToDetail(each.bookId)}
              sx={{ cursor: 'pointer' }}
            >
              <CardMedia
                component="img"
                alt={`book Image${each.title}`}
                image={each.img}
                sx={{
                  width: { xs: '180px', lg: '240px' },
                  height: { xs: '265px', lg: '352px' },
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
              <Box sx={{ width: 1 }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={[
                    each.isPublished === 0 && { backgroundColor: 'rgba(0,0,0, 0.4)' },
                    { width: 1, height: 1 },
                  ]}
                >
                  {/* 위쪽 텍스트 */}
                  <Typography fontSize={{ lg: 14 }} color="#666666" sx={{ pt: '8px' }}>
                    {each.intro && each.intro}
                  </Typography>

                  {/* 아래쪽 영역 */}
                  <Box sx={{ width: 1 }}>
                    <NormalContentsTitle sx={{ mt: '10px' }}>{each.title}</NormalContentsTitle>

                    {/* 가로선 */}
                    <Box
                      sx={{
                        height: { lg: '0.8px', xl: '1px' },
                        backgroundColor: '#DADADA',
                        my: { lg: '8px', xl: '10px' },
                      }}
                    />

                    <NormalContentsSubTitle>{each.subTitle}</NormalContentsSubTitle>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ lg: '3px', xl: '4px' }}
                      sx={{ mt: '4px', mb: '2px' }}
                    >
                      <Typography fontSize={{ lg: 14 }} color="#666666">
                        글
                      </Typography>
                      <Typography fontSize={{ lg: 14 }}>{each.author}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ lg: '4px' }}
                    >
                      <Typography fontSize={{ lg: 14 }} color="#666666">
                        출간일
                      </Typography>
                      <Typography fontSize={{ lg: 14 }}>
                        {changeDateDash(each.publishedAt)}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Stack>

            <Grid container justifyContent="space-between" sx={{ mt: '15px' }}>
              <Button variant="outlined" onClick={() => goToEdit(each.bookId)}>
                수정하기
              </Button>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="10px">
                <Box sx={{ fontWeight: 600 }}>공개 : {each.isPublished === 1 ? 'O' : 'X'}</Box>
                <Box>조회수 : {each.viewCounting}</Box>
                <Box>{`작성일 : ${changeDateDash(each.createdAt)}`}</Box>
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

AdminBookList.defaultProps = {
  bookList: [],
  category: '',
};

AdminBookList.propTypes = {
  // eslint-disable-next-line
  bookList: PropTypes.array,
  category: PropTypes.string,
};
