import React from 'react';
import BookSection from '../../../component/UI/BookSection';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';

export default function EBook() {
  return (
    <div>
      <FirstSecionSimple
        titleText="더라인북스 전자책"
        titleColor="#333333"
        subNav
        backImg="Books/ebook_background.png"
      />
      <BookSection category="전자책" />
    </div>
  );
}
