import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { Container, Stack, Typography, Box } from '@mui/material';
import { FixedContainer } from '../UI/ContainerStyle';

/** 현재 위치를 알려주는 회색 바 */
// 반투명 옵션 (ex) 메인은 불투명, 메인 외 반투명바)
export default function SubNavBar({ translucent }) {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[1].toLowerCase();
  const navigate = useNavigate();

  // showingBox가Ref가 화면에 나타나면 inView는 true, 아니면 false를 반환
  const [showingBoxRef, inView] = useInView();
  const [isShwoing, setIsShowing] = useState(true);

  const booksMenuList = [
    { label: '신간', link: '/Books/New', detailURL: 'xxx' },
    { label: '단행본', link: '/Books/Monograph', detailURL: '단행본' },
    { label: '전자책', link: '/Books/E-Book', detailURL: '전자책' },
    { label: '북트레일러', link: '/Books/BookTrailer', detailURL: 'xxx' },
    { label: '저자인터뷰', link: '/Books/Interview', detailURL: 'xxx' },
  ];

  const academyMenuList = [
    { label: '강의소식', link: '/Academy/LectureNews', detailURL: 'Academy/Notice' },
    { label: '온라인강의', link: '/Academy/Online', detailURL: '온라인 강의' },
    { label: '오프라인강의', link: '/Academy/Offline', detailURL: '오프라인 강의' },
    { label: '특강', link: '/Academy/SpecialLecture', detailURL: '특강' },
    { label: '동영상강의', link: '/Academy/Video', detailURL: 'xxx' },
    { label: 'TLS다운로드', link: '/Academy/TLS-Download', detailURL: 'xxx' },
  ];
  const menuObj = { booksMenuList, academyMenuList };

  useEffect(() => {
    if (inView) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  }, [inView]);

  const goToLink = link => {
    navigate(link);
  };

  return (
    <div>
      <Box ref={showingBoxRef} sx={{ height: '0.1px' }} />
      <Container
        disableGutters
        maxWidth={false}
        sx={[
          translucent && isShwoing
            ? { background: '#FFF', opacity: 0.74 }
            : {
                // 투명바 아닐 시 고정 + 디자인
                backgroundColor: '#F8F9FE',
                position: 'fixed',
                zIndex: 100,
                borderBottom: '0.2px solid #C9C9C9',
              },
          { display: { xs: 'none', lg: 'block' } },
        ]}
      >
        <FixedContainer>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={{ lg: '18px', xl: '20px' }}
            sx={[
              location.pathname.includes('/Academy')
                ? { pl: { lg: '151px', xl: '180px' } }
                : { pl: { lg: '56px', xl: '72px' } },
              {
                height: { xs: '30px', md: '40px', lg: '44px', xl: '48px' },
              },
            ]}
          >
            {/* 현재 탭에 따라 다른 목록을 */}
            {menuObj[`${currentTab}MenuList`].map(function (each) {
              return (
                <Typography
                  key={each.label}
                  fontSize={{ lg: 15, xl: 16 }}
                  fontWeight={
                    location.pathname === each.link || location.pathname.includes(each.detailURL)
                      ? 700
                      : 400
                  }
                  onClick={() => {
                    goToLink(each.link);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {each.label}
                </Typography>
              );
            })}
          </Stack>
        </FixedContainer>
      </Container>
      {!translucent && <Box sx={{ height: { xs: '0px', md: '0px', lg: '44px', xl: '48px' } }} />}
    </div>
  );
}

SubNavBar.defaultProps = {
  translucent: false,
};

SubNavBar.propTypes = {
  translucent: PropTypes.bool,
};
