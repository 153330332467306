import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import CommunityIntro from './CommunityIntro';

export default function TheLineCommunity() {
  return (
    <div>
      <FirstSecionSimple
        titleText="커뮤니티"
        backImg="integrated/notice_community_background.png"
      />
      <GrayPositionBar positionText1="커뮤니티" />
      <CommunityIntro />
    </div>
  );
}
