import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BodyContainer } from './ContainerStyle';
import { BodyTitleWithSearch } from './BodyTitleSection';
import BookList from './BookList';
import Pagination from '../Pagination';
import { bookHook } from '../../module/useEffectHooks';

export default function BookSection({ category }) {
  const [searchInput, setSearchInput] = useState('');
  const [refreshSwitch, setRefreshSwtich] = useState(true);

  const [bookList, page, setPage, totalItem, bookItemNumber] = bookHook(
    category,
    searchInput,
    refreshSwitch,
  );

  return (
    <BodyContainer>
      <BodyTitleWithSearch
        mainTitle={category}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        refreshSwitch={refreshSwitch}
        setRefreshSwtich={setRefreshSwtich}
      />
      <BookList bookList={bookList} />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={bookItemNumber} />
    </BodyContainer>
  );
}

BookSection.defaultProps = {
  category: '카테고리를 입력해주세요',
};

BookSection.propTypes = {
  category: PropTypes.string,
};
