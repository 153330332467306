/* eslint-disable */
import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BodyTitle } from '../../../component/UI/TextStyle';
import QuillEditorViewer from '../../../component/UI/QuillEditorViewer';

export default function MustRead({ mustReadData }) {
  return (
    <Box
      sx={{
        backgroundColor: '#F8F9FE',
        mt: '50px',
        px: { xs: '20px', lg: '45px' },
        py: { xs: '35px', lg: '50px' },
        mb: { lg: '-30px' },
      }}
    >
      <Box
        sx={{
          width: '24px',
          height: '6px',
          backgroundColor: '#161F2C',
          mb: '20px',
        }}
      />
      <Box sx={{ mx: '-15px' }}>
        <QuillEditorViewer contents={mustReadData} />
      </Box>
    </Box>
  );
}

MustRead.defaultProps = {
  mustReadData: '',
};

MustRead.propTypes = {
  mustReadData: PropTypes.string,
};
