import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { TheLineBodyContainer } from '../../../component/UI/ContainerStyle';
import { BodyTitle } from '../../../component/UI/TextStyle';
import { bookItemNumber, url } from '../../../component/commonVariable';
import Pagination from '../../../component/Pagination';
import AdminBookList from './AdminBookList';

export default function AdminBookSection({ category }) {
  const navigate = useNavigate();

  const [bookList, setBookList] = useState([{ bookId: 1, title: '로딩중입니다', img: '' }]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  useEffect(() => {
    axios.get(`${url}/admin/books/book/getList/${category}/${page}`).then(result => {
      // console.log(result.data);
      setBookList(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [page]);

  useEffect(() => {
    axios.get(`${url}/admin/books/book/getTotalNum/${category}`).then(result => {
      setTotalItem(result.data.totalNum);
    });
  }, []);

  const goToWrite = () => {
    if (category === '신간') {
      alert("단행본/전자책에서 '신간' 유무를 체크해주세요");
    } else {
      navigate(`/Admin/Books/EditBook/${category}/add`);
    }
  };

  return (
    <TheLineBodyContainer>
      <Stack direction="row" spacing="30px" sx={{ mb: '20px' }}>
        <BodyTitle>{category} 관리</BodyTitle>
        <Button onClick={goToWrite} sx={{ fontSize: '15px' }}>
          새 서적 등록
        </Button>
      </Stack>

      <AdminBookList bookList={bookList} category={category} />

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNum={bookItemNumber} />
    </TheLineBodyContainer>
  );
}

AdminBookSection.defaultProps = {
  category: '',
};

AdminBookSection.propTypes = {
  // eslint-disable-next-line
  category: PropTypes.string,
};
