import { Container, Grid, Typography, Stack, CardMedia } from '@mui/material';
import React from 'react';
import SubNavBar from '../../../component/NavBar/SubNavBar';
import { BannerTitle } from '../../../component/UI/TextStyle';
import ArrowRightWhiteIcon from '../../../assets/arrowRightWhite.png';
import { backgroundURL } from '../../../component/commonFunction';

export default function TLSFirstSection() {
  const openTLS = () => {
    window.open('https://thelinemedia.co.kr/#TLS-DOWNLOAD');
  };

  const downloadTLS = () => {
    window.open('http://thelinemedia.co.kr/#TLS-DOWNLOAD_MOB');
  };

  return (
    <Container
      className="TLSDownload"
      disableGutters
      maxWidth={false}
      sx={{
        backgroundImage: {
          xs: backgroundURL('Academy/tls_background.png', true),
          lg: backgroundURL('Academy/tls_background.png', false),
        },
        backgroundSize: 'cover',
        height: { xs: '240px', md: '360px', lg: '450px', xl: '491px' },
      }}
    >
      <SubNavBar translucent />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: { xs: '240px', md: '300px', lg: '406px', xl: '443px' } }}
      >
        <BannerTitle color="#FFF">TLS 다운로드</BannerTitle>
        {/* Mob 텍스트 */}
        <Typography
          component="div"
          color="#FFF"
          fontSize={{ xs: 10 }}
          sx={{
            whiteSpace: 'pre-wrap',
            mt: { xs: '5px' },
            display: { lg: 'none' },
          }}
        >
          TLS(The Line Subtitle)는 더라인미디어와 더라인아카데미가 개발한
          <br />
          자막 편집 프로그램으로 누구나 사용할 수 있는 프리웨어입니다.
          <br />
          ATS, SE 등 다른 자막 편집 프로그램들의 장점만을 모아 놨습니다.
          <br />
          <br />
          <b>동영상을 보면서 사용법을 익혀 보세요.</b>
        </Typography>

        {/* PC 텍스트 */}
        <Typography
          component="div"
          color="#FFF"
          fontSize={{ lg: 16 }}
          sx={{
            whiteSpace: 'pre-wrap',
            mt: { lg: '15px' },
            display: { xs: 'none', lg: 'block' },
          }}
        >
          TLS(The Line Subtitle)는 더라인미디어와 더라인아카데미가 개발한 자막 편집 프로그램으로
          <br />
          누구나 사용할 수 있는 프리웨어입니다.
          <br />
          ATS, SE 등 다른 자막 편집 프로그램들의 장점만을 모아 놨습니다.
          <br />
          <br />
          <b>동영상을 보면서 사용법을 익혀 보세요.</b>
        </Typography>

        {/* PC 링크 오픈 */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing="15px"
          onClick={openTLS}
          sx={{
            maxWidth: '406px',
            backgroundColor: '#4891FF',
            borderRadius: { xs: '8px', lg: '16px' },
            mt: { xs: '20px', lg: '40px' },
            px: { xs: '50px', lg: '135px' },
            py: { xs: '8px', lg: '16px', xl: '18px' },
            cursor: 'pointer',
            ':hover': {
              opacity: 0.859,
            },
            display: { xs: 'none', lg: 'inherit' },
          }}
        >
          <Typography fontSize={{ lg: 18, xl: 20 }} fontWeight={700} color="#FFF">
            TLS 다운로드
          </Typography>
          <CardMedia
            component="img"
            image={ArrowRightWhiteIcon}
            alt="arrow"
            sx={{ width: '8px', height: '14px' }}
          />
        </Stack>

        {/* 모바일 바로 다운 */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing="15px"
          onClick={downloadTLS}
          sx={{
            maxWidth: '406px',
            backgroundColor: '#4891FF',
            borderRadius: { xs: '8px', lg: '16px' },
            mt: { xs: '20px', lg: '40px' },
            px: { xs: '50px', lg: '135px' },
            py: { xs: '8px', lg: '16px', xl: '18px' },
            cursor: 'pointer',
            ':hover': {
              opacity: 0.859,
            },
            display: { lg: 'none' },
          }}
        >
          <Typography fontSize={{ lg: 18, xl: 20 }} fontWeight={700} color="#FFF">
            TLS 다운로드
          </Typography>
          <CardMedia
            component="img"
            image={ArrowRightWhiteIcon}
            alt="arrow"
            sx={{ width: '8px', height: '14px' }}
          />
        </Stack>
      </Grid>
    </Container>
  );
}
