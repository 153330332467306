import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import Location from './Location';

export default function TheLineNotice() {
  return (
    <div>
      <FirstSecionSimple titleText="찾아오는길" backImg="integrated/contact_background.png" />
      <GrayPositionBar positionText1="찾아오는길" />
      <Location />
    </div>
  );
}
