import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { BodyTitle } from '../../../component/UI/TextStyle';
import LectureList from '../../../component/UI/LectureList';
import { lectureMainHook } from '../../../module/useEffectHooks';

export default function LectureParts({ type }) {
  const lectureList = lectureMainHook(type);

  return (
    <Box sx={{ mb: '70px' }}>
      <BodyTitle>{type}</BodyTitle>
      <Box sx={{ height: '1px', backgroundColor: '#DADADA', mb: '20px', mt: '12px' }} />
      <LectureList lectureList={lectureList} isMain />
    </Box>
  );
}

LectureParts.defaultProps = {
  type: '타입을 입력해주세요',
};

LectureParts.propTypes = {
  type: PropTypes.string,
};
