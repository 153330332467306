import React from 'react';
import GrayPositionBar from '../../../component/UI/GrayPositionBar';
import NoticeSection from '../../../component/UI/NoticeSection';
import SubNavBar from '../../../component/NavBar/SubNavBar';

export default function LectureNews() {
  return (
    <div>
      <SubNavBar />
      <GrayPositionBar positionText1="더라인아카데미" positionText2="강의소식" />
      <NoticeSection />
    </div>
  );
}
