import React, { useState } from 'react';
import axios from 'axios';
import { Modal, TextField, Stack, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import ConfirmModal from '../ConfirmModal';
import { url } from '../../commonVariable';

export default function AddEditYoutubeModal({
  modalSwitch,
  setModalSwitch,
  editData,
  refreshSwitch,
  setRefreshSwitch,
  whichCompany,
  category,
}) {
  // edit 인풋
  const editField = [
    { label: '순서', keyName: 'youtubeOrder' },
    { label: '제목', keyName: 'title' },
    { label: '부제목', keyName: 'subTitle' },
    { label: '유튜브 주소', keyName: 'link' },
  ];
  const [editInput, setEditInput] = useState(JSON.parse(JSON.stringify(editData)));

  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const onChangeEditInput = e => {
    const { name, value } = e.target;
    setEditInput({
      ...editInput,
      [name]: value,
    });
  };

  const modalClose = () => {
    setModalSwitch(false);
  };

  const saveData = () => {
    const body = { ...editInput, category };
    const mode = Object.keys(editData).length === 0 ? 'add' : 'edit';

    if (!editInput.title) {
      alert('제목을 입력해주세요.');
    } else if (!editInput.subTitle) {
      alert('부제목을 입력해주세요.');
    } else if (!editInput.link) {
      alert('링크를 입력해주세요.');
    } else {
      axios.post(`${url}/admin/${whichCompany}/youtube/${mode}`, body).then(() => {
        setRefreshSwitch(!refreshSwitch);
        alert('정상적으로 완료되었습니다');
        modalClose();
      });
    }
  };

  const openDeleteModal = () => {
    setDeleteConfirmModal(true);
  };

  const deleteYoutube = () => {
    axios.post(`${url}/admin/books/youtube/delete/${editData.youtubeId}`).then(() => {
      setRefreshSwitch(!refreshSwitch);
      alert('삭제가 완료되었습니다.');
      modalClose();
    });
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 700,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 40px 45px 40px',
            outline: 'none',
          }}
        >
          {editField.map(function (each, index) {
            return (
              <Stack
                key={each.label}
                direction="row"
                spacing={3}
                alignItems="center"
                sx={{ mb: '10px' }}
              >
                <Box sx={{ width: '90px' }}>
                  <h4>{each.label}</h4>
                </Box>
                <TextField
                  fullWidth
                  type={index === 0 && 'number'}
                  name={each.keyName}
                  value={editInput[each.keyName] || ''}
                  onChange={onChangeEditInput}
                />
              </Stack>
            );
          })}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={saveData}
              sx={{ color: 'white', mr: '30px', width: '100px' }}
            >
              {Object.keys(editData).length === 0 ? '추가하기' : '수정하기'}
            </Button>
            {Object.keys(editData).length !== 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={openDeleteModal}
                sx={{ width: '100px', mr: '30px' }}
              >
                삭제하기
              </Button>
            )}
            <Button variant="contained" color="grey" onClick={modalClose} sx={{ width: '100px' }}>
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>

      {deleteConfirmModal && (
        <ConfirmModal
          modalSwitch={deleteConfirmModal}
          setModalSwitch={setDeleteConfirmModal}
          title="삭제 확인"
          contents="정말 해당 게시물을 삭제하시겠습니까?"
          func={deleteYoutube}
        />
      )}
    </div>
  );
}

AddEditYoutubeModal.defaultProps = {
  editData: {},
  modalSwitch: true,
  setModalSwitch: () => {},
  refreshSwitch: true,
  setRefreshSwitch: () => {},
  whichCompany: 'books || Academy',
  category: '어떤 유튜브인지 입력해주세요',
};

AddEditYoutubeModal.propTypes = {
  // eslint-disable-next-line
  editData: PropTypes.object,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
  whichCompany: PropTypes.string,
  category: PropTypes.string,
};
