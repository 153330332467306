import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  CardMedia,
  Stack,
  Box,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import ArrowRightWhiteIcon from '../../../assets/arrowRightWhite.png';
import { url } from '../../../component/commonVariable';
import { changeDateDash, addComma } from '../../../component/commonFunction';

export default function BookIntroMob() {
  const { bookId } = useParams();
  const [bookData, setBookData] = useState({});

  const dataList = [
    { label: '가격', keyName: 'price' },
    { label: '분야', keyName: 'genre' },
    { label: '출간일', keyName: 'publishedAt' },
    { label: '쪽수', keyName: 'page' },
    { label: 'ISBN', keyName: 'isbn' },
  ];

  useEffect(() => {
    axios.get(`${url}/books/book/getDetail/text/${bookId}`).then(result => {
      setBookData(result.data[0]);
    });
  }, []);

  const openNewLink = link => {
    window.open(link);
  };

  return (
    <Box>
      <Box
        sx={{
          border: '0.5px solid #DADADA',
          mt: '-15px',
          px: '25px',
          py: '29px',
        }}
      >
        <CardMedia
          component="img"
          alt={`book Image${bookData.title}`}
          image={bookData.img || ''}
          sx={{
            position: 'relative',
            width: 1,
            aspectRatio: '293/431',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        />
      </Box>

      <Box sx={{ px: '24px', mt: '20px' }}>
        <Typography align="left" fontSize={{ xs: 16 }} fontWeight={600}>
          {bookData.title}
        </Typography>

        <Table sx={{ mt: '12px' }}>
          <TableBody>
            {dataList.map(function (each) {
              return (
                <TableRow key={each.label} sx={{ borderTop: '0.5px solid #C9C9C9' }}>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: '#F8F9FE',
                      fontSize: '13px',
                      fontWeight: 600,
                      width: '40px',
                      py: '7px',
                    }}
                  >
                    {each.label}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: '13px',
                      pl: '15px',
                      py: '7px',
                    }}
                  >
                    {each.label !== '출간일' && each.label !== '가격' && bookData[each.keyName]}
                    {each.label === '출간일' && changeDateDash(bookData[each.keyName])}
                    {each.label === '가격' && `${addComma(bookData[each.keyName])}원`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {bookData.paperBookLink && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="15px"
            onClick={() => openNewLink(bookData.paperBookLink)}
            sx={{
              backgroundColor: '#4891FF',
              borderRadius: '8px',
              py: { xs: '7px' },
              mt: '20px',
              cursor: 'pointer',
            }}
          >
            <Typography fontSize={{ xs: 14 }} fontWeight={700} color="#FFF">
              종이책 구매 바로가기
            </Typography>
            <CardMedia
              component="img"
              image={ArrowRightWhiteIcon}
              alt="arrow"
              sx={{ width: '8px', height: '14px' }}
            />
          </Stack>
        )}

        {bookData.eBookLink && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="12px"
            onClick={() => openNewLink(bookData.eBookLink)}
            sx={{
              backgroundColor: '#76C121',
              borderRadius: '8px',
              py: { xs: '7px' },
              mt: '10px',
              cursor: 'pointer',
            }}
          >
            <Typography fontSize={{ xs: 14 }} fontWeight={700} color="#FFF">
              전자책 구매 바로가기
            </Typography>
            <CardMedia
              component="img"
              image={ArrowRightWhiteIcon}
              alt="arrow"
              sx={{ width: '8px', height: '14px' }}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
}
