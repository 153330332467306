import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from './ContextAPI';

export default function LoginCheck() {
  const { isLogin } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate('/Login');
    }
  }, []);

  return null;
}
