import React from 'react';
import { Box } from '@mui/material';
import SubNavBar from '../../../component/NavBar/SubNavBar';
import LectureIntroPC from './LectureIntroPC';
import LectureIntroMob from './LectureIntroMob';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import LectureDescription from './LectureDescription';

export default function LectureDetail() {
  return (
    <div>
      <SubNavBar />

      <BodyContainer>
        {/* Mob Intro */}
        <Box sx={{ display: { lg: 'none' } }}>
          <LectureIntroMob />
        </Box>

        {/* PC Intro */}
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <LectureIntroPC />
        </Box>

        <LectureDescription />
      </BodyContainer>
    </div>
  );
}
