import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardMedia, Grid, Stack, Box, Typography } from '@mui/material';
import { NormalContentsTitle, NormalContentsSubTitle } from './TextStyle';
import { changeDateDot } from '../commonFunction';

/** 책 리스트 UI */
export default function BookList({ bookList }) {
  const navigate = useNavigate();

  const goToDetail = (id, category) => {
    navigate(`/Books/Book/${category}/${id}`);
  };

  return (
    <Grid container spacing={{ xs: '23px', lg: '40px' }}>
      {/* PC. 나중에 UI 수정요청하여 급작스럽게 나눈 */}
      {bookList.map(function (each) {
        return (
          <Grid item xs={12} md={3} key={each.bookId} sx={{ display: { xs: 'none', lg: 'block' } }}>
            <Box onClick={() => goToDetail(each.bookId, each.category)} sx={{ cursor: 'pointer' }}>
              <CardMedia
                component="img"
                alt={`book Image${each.title}`}
                image={each.img || ''}
                sx={{
                  width: { xs: 0.4, lg: 1 },
                  aspectRatio: '240/352',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
              <Box sx={{ width: 1 }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ width: 1, height: 1 }}
                >
                  {/* 위쪽 텍스트 */}
                  <Typography fontSize={{ lg: 14 }} color="#666666" sx={{ pt: '8px' }}>
                    {each.intro && each.intro}
                  </Typography>
                  {/* 아래쪽 영역 */}
                  <Box sx={{ width: 1 }}>
                    <NormalContentsTitle sx={{ mt: '10px' }}>{each.title}</NormalContentsTitle>
                    {/* 가로선 */}
                    <Box
                      sx={{
                        height: { xs: '0.5px', lg: '0.8px', xl: '1px' },
                        backgroundColor: '#DADADA',
                        my: { xs: '6px', lg: '8px', xl: '10px' },
                      }}
                    />
                    <NormalContentsSubTitle>{each.subTitle}</NormalContentsSubTitle>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ lg: '3px', xl: '4px' }}
                      sx={{ mt: '4px', mb: '2px' }}
                    >
                      <Typography fontSize={{ lg: 14 }} color="#666666">
                        글
                      </Typography>
                      <Typography fontSize={{ lg: 14 }}>{each.author}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ lg: '4px' }}
                    >
                      <Typography fontSize={{ lg: 14 }} color="#666666">
                        출간일
                      </Typography>
                      <Typography fontSize={{ lg: 14 }}>
                        {changeDateDot(each.publishedAt)}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                display: { md: 'none' },
                height: { xs: '0.5px' },
                backgroundColor: '#DADADA',
                mt: { xs: '30px' },
              }}
            />
          </Grid>
        );
      })}

      {/* Mob */}
      {bookList.map(function (each) {
        return (
          <Grid item xs={12} md={6} key={each.bookId} sx={{ display: { lg: 'none' } }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              spacing={{ xs: '14px' }}
              onClick={() => goToDetail(each.bookId, each.category)}
              sx={{ cursor: 'pointer' }}
            >
              <CardMedia
                component="img"
                alt={`book Image${each.title}`}
                image={each.img || ''}
                sx={{
                  width: { xs: 0.4 },
                  aspectRatio: '240/352',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
              <Box sx={{ width: 1 }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ width: 1, height: 1 }}
                >
                  {/* 위쪽 텍스트 */}
                  <Typography fontSize={{ lg: 14 }} color="#666666" sx={{ pt: '8px' }}>
                    {each.intro && each.intro}
                  </Typography>

                  {/* 아래쪽 영역 */}
                  <Box sx={{ width: 1 }}>
                    <NormalContentsTitle sx={{ mt: '10px' }}>{each.title}</NormalContentsTitle>

                    {/* 가로선 */}
                    <Box
                      sx={{
                        height: { xs: '0.5px', lg: '0.8px', xl: '1px' },
                        backgroundColor: '#DADADA',
                        my: { xs: '6px', lg: '8px', xl: '10px' },
                      }}
                    />

                    <NormalContentsSubTitle>{each.subTitle}</NormalContentsSubTitle>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ xs: '3px' }}
                      sx={{ mt: '4px', mb: '2px' }}
                    >
                      <Typography fontSize={{ xs: 14 }} color="#666666">
                        글
                      </Typography>
                      <Typography
                        fontSize={{ xs: 14 }}
                        align="left"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {each.author}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ xs: '3px' }}
                    >
                      <Typography fontSize={{ xs: 14 }} color="#666666">
                        출간일
                      </Typography>
                      <Typography fontSize={{ xs: 14 }}>
                        {changeDateDot(each.publishedAt)}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </Stack>

            <Box
              sx={{
                display: { md: 'none' },
                height: { xs: '0.5px' },
                backgroundColor: '#DADADA',
                mt: { xs: '30px' },
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

BookList.defaultProps = {
  bookList: [],
};

BookList.propTypes = {
  // eslint-disable-next-line
  bookList: PropTypes.array,
};
