import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, TableBody, TableRow, TableCell, Button, Stack } from '@mui/material';
import { TheLineBodyContainer } from '../ContainerStyle';
import { BodyTitle } from '../TextStyle';
import { CategoryBadge } from '../Badge';
import { noticeItemNumber, url } from '../../commonVariable';
import { changeDateDash } from '../../commonFunction';
import Pagination from '../../Pagination';

export default function AdminNoticeSection() {
  const navigate = useNavigate();

  const whichCompany = window.location.pathname.split('/')[2].toLowerCase();

  const [noticeList, setNoticeList] = useState([{ noticeId: 1, title: '로딩중입니다' }]);
  const [page, setPage] = useState(1);
  const [noticeItemObj, setNoticeItemObj] = useState({ totalNum: 14, important: 2, normal: 12 });

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/Notice/getList/${page}`).then(result => {
      setNoticeList(result.data);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [page]);

  useEffect(() => {
    axios.get(`${url}/admin/${whichCompany}/Notice/getTotalNum`).then(result => {
      setNoticeItemObj(result.data);
    });
  }, []);

  const openWindow = id => {
    if (whichCompany === 'theline') {
      window.open(`${window.location.origin}/Notice/${id}`);
    } else if (whichCompany === 'academy') {
      window.open(`${window.location.origin}/Academy/Notice/${id}`);
    }
  };

  const goToWrite = () => {
    navigate(`/Admin/${whichCompany}/EditNotice/add`);
  };

  const goToEdit = id => {
    navigate(`/Admin/${whichCompany}/EditNotice/${id}`);
  };

  return (
    <TheLineBodyContainer>
      <Stack direction="row" spacing="30px">
        <BodyTitle>{whichCompany === 'theline' ? '공지사항 관리' : '강의소식 관리'}</BodyTitle>
        <Button onClick={goToWrite} sx={{ fontSize: '15px' }}>
          새 글 작성
        </Button>
      </Stack>
      <Table sx={{ mt: { xs: '18px', lg: '15px', xl: '21px' } }}>
        <TableBody
          sx={{
            borderTop: { xs: '0.8px solid #222222', xl: '1px solid #222222' },
            borderBottom: { xs: '0.8px solid #222222', xl: '1px solid #222222' },
          }}
        >
          {noticeList.map(function (each) {
            return (
              <TableRow
                key={each.noticeId}
                sx={[
                  each.isImportant === 1 ? { backgroundColor: '#F5FBFF' } : null,
                  {
                    '&:last-child td': {
                      borderBottom: 0,
                    },
                  },
                ]}
              >
                <TableCell
                  sx={{
                    py: '0px',
                    px: '0px',
                    width: { lg: '65px', xl: '65px' },
                    height: { lg: '90px', xl: '105px' },
                    fontSize: { xs: '12px', lg: '15px', xl: '16px' },
                    fontWeight: 700,
                  }}
                >
                  {each.noticeId}
                </TableCell>
                <TableCell
                  sx={{
                    px: '0px',
                    width: { lg: '65px', xl: '70px' },
                    fontWeight: 700,
                    fontSize: { xs: '12px', lg: '15px', xl: '16px' },
                    color: '#FFF',
                    wordBreak: 'keep-all',
                  }}
                >
                  <CategoryBadge badgeText={each.category} />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: { xs: '12px', lg: '18px', xl: '20px' },
                    fontWeight: 500,
                  }}
                >
                  {each.title}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    minWidth: '100px',
                    color: '#666666',
                    fontSize: { xs: '12px', lg: '18px', xl: '20px' },
                  }}
                >
                  {changeDateDash(each.createdAt)}
                </TableCell>
                <TableCell
                  sx={{
                    minWidth: '80px',
                    color: '#666666',
                    fontSize: { xs: '12px', lg: '16px', xl: '16px' },
                  }}
                >
                  {`조회수 : ${each.viewCounting}`}
                </TableCell>
                <TableCell sx={{ width: '70px' }}>
                  <Button
                    onClick={() => openWindow(each.noticeId)}
                    color="grey"
                    sx={{ color: '#FFF' }}
                  >
                    조회
                  </Button>
                </TableCell>

                <TableCell sx={{ width: '70px' }}>
                  <Button color="secondary" onClick={() => goToEdit(each.noticeId)}>
                    수정
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Pagination
        page={page}
        setPage={setPage}
        totalItem={noticeItemObj.normal}
        itemNum={noticeItemNumber - noticeItemObj.important}
      />
    </TheLineBodyContainer>
  );
}
