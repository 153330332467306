import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  CardMedia,
  Stack,
  Box,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import ArrowRightWhiteIcon from '../../../assets/arrowRightWhite.png';
import { url } from '../../../component/commonVariable';
import { changeDateDash, addComma } from '../../../component/commonFunction';

export default function BookIntroPC() {
  const { bookId } = useParams();
  const [bookData, setBookData] = useState({});

  const location = useLocation();

  const dataList =
    location.pathname.includes('전자책') || decodeURI(location.pathname).includes('전자책')
      ? [
          { label: '저자', keyName: 'author' },
          { label: '가격', keyName: 'price' },
          { label: '분야', keyName: 'genre' },
          { label: '출간일', keyName: 'publishedAt' },
          { label: 'ISBN', keyName: 'isbn' },
        ]
      : [
          { label: '저자', keyName: 'author' },
          { label: '가격', keyName: 'price' },
          { label: '분야', keyName: 'genre' },
          { label: '출간일', keyName: 'publishedAt' },
          { label: '쪽수', keyName: 'page' },
          { label: 'ISBN', keyName: 'isbn' },
        ];

  useEffect(() => {
    axios.get(`${url}/books/book/getDetail/text/${bookId}`).then(result => {
      setBookData(result.data[0]);
    });
  }, []);

  const openNewLink = link => {
    window.open(link);
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      spacing={{ lg: '45px', xl: '48px' }}
      sx={{ px: '50px' }}
    >
      <CardMedia
        component="img"
        alt={`book Image${bookData.title}`}
        image={bookData.img || ''}
        sx={{
          width: { lg: '270px' },
          height: { lg: '405px' },
          aspectRatio: '2/3',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      />
      <Box sx={{ width: 1, pr: '100px' }}>
        <Typography align="left" fontSize={{ lg: 32 }} fontWeight={700}>
          {bookData.title}
        </Typography>

        <Table sx={{ mt: '14px' }}>
          <TableBody>
            {dataList.map(function (each) {
              return (
                <TableRow
                  key={each.label}
                  sx={{ borderTop: { lg: '0.5px solid #C9C9C9', xl: '1px solid #C9C9C9' } }}
                >
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: '#F8F9FE',
                      fontSize: { lg: '16px', xl: '18px' },
                      fontWeight: { lg: 600, xl: 700 },
                      width: '70px',
                      py: '8px',
                    }}
                  >
                    {each.label}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: { lg: '16px', xl: '18px' },
                      pl: '20px',
                      py: '8px',
                    }}
                  >
                    {each.label !== '출간일' && each.label !== '가격' && bookData[each.keyName]}
                    {each.label === '출간일' && changeDateDash(bookData[each.keyName])}
                    {each.label === '가격' && `${addComma(bookData[each.keyName])}원`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing="15px"
          sx={{ mt: '30px' }}
        >
          {bookData.paperBookLink && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing="15px"
              onClick={() => openNewLink(bookData.paperBookLink)}
              sx={{
                backgroundColor: '#4891FF',
                borderRadius: '8px',
                py: { lg: '9px' },
                px: { lg: '18px' },
                maxWidth: '206px',
                cursor: 'pointer',
              }}
            >
              <Typography fontSize={{ lg: 16, xl: 18 }} fontWeight={700} color="#FFF">
                종이책 구매 바로가기
              </Typography>
              <CardMedia
                component="img"
                image={ArrowRightWhiteIcon}
                alt="arrow"
                sx={{ width: '8px', height: '14px' }}
              />
            </Stack>
          )}

          {bookData.eBookLink && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing="15px"
              onClick={() => openNewLink(bookData.eBookLink)}
              sx={{
                backgroundColor: '#76C121',
                borderRadius: '8px',
                py: { lg: '9px' },
                px: { lg: '18px' },
                maxWidth: '206px',
                cursor: 'pointer',
              }}
            >
              <Typography fontSize={{ lg: 16, xl: 18 }} fontWeight={700} color="#FFF">
                전자책 구매 바로가기
              </Typography>
              <CardMedia
                component="img"
                image={ArrowRightWhiteIcon}
                alt="arrow"
                sx={{ width: '8px', height: '14px' }}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
