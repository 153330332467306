import React from 'react';
import { FirstSecionSimple } from '../../../component/UI/FirstSection';
import YoutubeSection from '../../../component/UI/YoutubeSection';

export default function Interview() {
  return (
    <div>
      <FirstSecionSimple
        titleText="더라인북스 저자 인터뷰"
        titleColor="#333333"
        subNav
        backImg="Books/interview_background.png"
      />
      <YoutubeSection company="books" category="저자 인터뷰" />
    </div>
  );
}
