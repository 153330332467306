import React from 'react';
import AdminNoticeSection from '../../../component/UI/Admin/AdminNoticeSection';
import { AdminFirstSecion } from '../../../component/UI/FirstSection';
import LoginCheck from '../../../module/LoginCheck';

export default function AdminTheLine() {
  LoginCheck();
  return (
    <div>
      <AdminFirstSecion titleText="더라인 통합 페이지 관리자" />
      <AdminNoticeSection />
    </div>
  );
}
