import React from 'react';
import { Box } from '@mui/material';
import SubNavBar from '../../../component/NavBar/SubNavBar';
import LectureParts from './LectureParts';
import { BodyContainer } from '../../../component/UI/ContainerStyle';
import YoutubeCarouselSection from '../../../component/UI/YoutubeCarouselSection';
import MainBanner from '../../../component/UI/MainBanner';
import VideoLectureParts from './VideoLectureParts';

export default function AcademyMain() {
  return (
    <div>
      <SubNavBar />

      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <MainBanner company="academy" category="아카데미 배너" device="PC" isMobile={false} />
      </Box>
      <Box sx={{ display: { lg: 'none' } }}>
        <MainBanner company="academy" category="아카데미 배너" device="Mob" isMobile />
      </Box>

      <BodyContainer>
        <LectureParts type="모집중 강의" />
        <LectureParts type="마감임박 강의" />

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <VideoLectureParts />
        </Box>

        <Box sx={{ display: { lg: 'none' }, mb: '40px' }}>
          <YoutubeCarouselSection
            title="동영상 강의"
            company="academy"
            category="동영상 강의"
            allLink="/Academy/Video"
          />
        </Box>

        <YoutubeCarouselSection
          title="영상번역 프로그램 TLS 사용법"
          company="academy"
          category="TLS 사용법 예시"
          allLink="/Academy/TLS-Download"
        />
      </BodyContainer>
    </div>
  );
}
