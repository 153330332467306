import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Grid, CardMedia } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FixedContainer } from '../UI/ContainerStyle';
import MobDrawler from './MobDrawler';

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const firstDepthPathName = `/${location.pathname.split('/')[1]}`;

  const homepageList = [
    { label: '더라인북스', link: '/Books' },
    { label: '더라인아카데미', link: '/Academy' },
  ];

  const thelineMenuList = [
    { label: '공지', link: '/Notice' },
    { label: '커뮤니티', link: '/Community' },
    { label: '더라인TV', link: '/TV' },
    { label: '찾아오는길', link: '/Contact' },
  ];

  const [drawerMenu, setDrawerMenu] = useState(null);

  const handleOpenNavMenu = () => {
    setDrawerMenu(true);
  };

  const handleCloseNavMenu = () => {
    setDrawerMenu(false);
  };

  function goToLink(link) {
    if (link === '/TV') {
      window.open('https://www.youtube.com/channel/UCeE3VUCbysuJfjdQgzXHQ-g');
    } else {
      navigate(link);
    }
  }

  return (
    <div>
      <nav>
        <AppBar elevation={0} sx={{ backgroundColor: '#FFF', borderBottom: '0.2px solid #C9C9C9' }}>
          <FixedContainer>
            <Toolbar
              disableGutters
              variant="dense"
              sx={{ height: { xs: '54px', lg: '60px', xl: '72px' } }}
            >
              <CardMedia
                onClick={() => {
                  goToLink('/');
                }}
                component="img"
                image="/logo192.png"
                alt="logo"
                sx={{
                  width: { sm: '120px', lg: '38px', xl: '42px' },
                  cursor: 'pointer',
                  display: { xs: 'none', lg: 'flex' },
                  mr: { md: '35px', lg: '18px', xl: '30px' },
                }}
              />

              {/* 모바일 부분 */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { xs: 'flex', lg: 'none' } }}
              >
                {/* 모바일 가운데 로고 부분 (md부터는 none) */}
                <Box>
                  <CardMedia
                    onClick={() => {
                      goToLink('/');
                    }}
                    component="img"
                    image="/logo192.png"
                    alt="logo"
                    sx={{ width: '42px', cursor: 'pointer', display: { xs: 'flex', lg: 'none' } }}
                  />
                </Box>

                <Box>
                  <IconButton size="large" onClick={handleOpenNavMenu} sx={{ p: 0 }}>
                    <MenuIcon sx={{ color: '#4891FF', fontSize: '30px' }} />
                  </IconButton>

                  {/* 모바일 메뉴. 목록 촤르륵.  */}
                  {drawerMenu && <MobDrawler open={drawerMenu} onClose={handleCloseNavMenu} />}
                </Box>
              </Grid>

              {/* 웹 메뉴. 모바일x (md미만 none.) */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', lg: 'flex' },
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {homepageList.map(each => (
                  <Typography
                    key={each.label}
                    onClick={() => {
                      goToLink(each.link);
                    }}
                    fontWeight={firstDepthPathName === each.link ? 700 : 500}
                    sx={{
                      mr: { md: '15px', lg: '18px', xl: '20px' },
                      cursor: 'pointer',
                      fontSize: { lg: '18px', xl: '20px' },
                    }}
                  >
                    {each.label}
                  </Typography>
                ))}
              </Box>

              {/* 웹 공지, 커뮤니티, 더라인TV, 찾아오는길 */}
              {thelineMenuList.map(each => (
                <Typography
                  key={each.label}
                  onClick={() => {
                    goToLink(each.link);
                  }}
                  fontWeight={location.pathname === each.link ? 700 : 500}
                  sx={{
                    ml: { md: '15px', lg: '17px', xl: '18px' },
                    cursor: 'pointer',
                    fontSize: { lg: '15px', xl: '16px' },
                    display: { xs: 'none', lg: 'block' },
                  }}
                >
                  {each.label}
                </Typography>
              ))}
            </Toolbar>
          </FixedContainer>
        </AppBar>
      </nav>
    </div>
  );
}

export default NavBar;
